import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Button from '../Button';

import settings from '../../config';

const FabricVariantTransactionForm = () => {
  const navigate = useNavigate();
  const { fabricTransactionId = '', fabricVariantTransactionId = null } =
    useParams();
  const [variants, setVariants] = useState([]);

  const [formData, setFormData] = useState({
    fabricTransactionId,
    fabricVariantTransactionId,
    variantId: '',
    variantCode: '',
    variantType: '',
    quantity: '',
    price: '',
    processingDate: '',
    category: '',
    status: 0
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variants/`);

      setVariants(response.data.data);
    })();
  }, []);

  useEffect(() => {
    if (fabricVariantTransactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}fabric/variant-view-transaction`,
          {
            fabricVariantTransactionId
          }
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [fabricVariantTransactionId]);

  useEffect(() => {
    if (fabricVariantTransactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}fabric/variant-view-transaction`,
          {
            fabricVariantTransactionId
          }
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [fabricVariantTransactionId]);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (fabricVariantTransactionId &&
            (await axios.post(
              `${settings.api}fabric/update-variant-transaction`,
              formData
            ))) ||
          (await axios.post(
            `${settings.api}fabric/create-variant-transaction`,
            formData
          ));

        if (response.data.statusCode === 200) {
          navigate(`/fabric/variants/${fabricTransactionId}`);
        }
      }}
    >
      <div className='w-full lg:w-1/2'>
        <div className='bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4'>
          <div className='flex flex-col text-sm text-gray-500 py-4'>
            <div className='flex flex-row justify-center space-x-10 w-full'>
              <div className='w-full font-bold text-xl text-center mb-5'>
                استماره دفعات المنتجات
              </div>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                الاصناف
                <select
                  value={formData.variantId}
                  name='variantId'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  pattern='[0-9]{4}-[0-9]2}-[0-9]{2}'
                >
                  <option value=''>اختر</option>
                  {variants.map((variant) => (
                    <option key={variant.variantId} value={variant.variantId}>
                      {variant.variantName}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                كود الصنف
                <input
                  placeholder='كود الصنف'
                  value={formData.variantCode}
                  name='variantCode'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                />
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                نوع الصنف
                <input
                  placeholder='نوع الصنف'
                  value={formData.variantType}
                  name='variantType'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                />
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                العدد
                <input
                  placeholder='العدد'
                  value={formData.quantity}
                  name='quantity'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                />
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                سعر الخامات للقطعه
                <input
                  placeholder='سعر الخامات للقطعه'
                  value={formData.price}
                  name='price'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                />
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                الحاله
                <select
                  value={formData.status}
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  name='status'
                >
                  <option value='0'>اختر</option>
                  <option value='1'>دوره تشغيل</option>
                  <option value='2'>فى المخزن</option>
                </select>
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                القسم
                <input
                  placeholder='القسم'
                  value={formData.category}
                  name='category'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                />
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                تاريخ الدفعه
                <input
                  placeholder='yyyy-mm-dd'
                  value={formData.processingDate}
                  name='processingDate'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}'
                />
              </label>
            </div>

            <div className='flex flex-row justify-center space-x-10 w-full mt-5'>
              <div className='w-1/2'>
                <Button
                  type='submit'
                  text='تسجيل'
                  isDisabled={
                    !(
                      (formData.variantId || formData.fabricTransactionId) &&
                      formData.quantity &&
                      formData.processingDate &&
                      formData.variantCode &&
                      formData.variantType
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FabricVariantTransactionForm;
