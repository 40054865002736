import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";

const AssetDetailsForm = () => {
  const navigate = useNavigate();
  const { assetId = "", assetDetailId = null } = useParams();

  const [formData, setFormData] = useState({
    assetId,
    quantity: 0,
    price: 0,
    instalmentDate: "",
  });

  useEffect(() => {
    if (assetDetailId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}assets/view-details`,
          {
            assetDetailId,
          }
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [assetDetailId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (assetDetailId &&
            (await axios.post(
              `${settings.api}assets/update-details`,
              formData
            ))) ||
          (await axios.post(`${settings.api}assets/create-details`, formData));

        if (response.data.statusCode === 200) {
          navigate(`/asset/details/${assetId}`);
        }
      }}
    >
      <div className="w-full lg:w-1/2">
        <div className="bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4">
          <div className="flex flex-col text-sm text-gray-500 py-4">
            <div className="flex flex-row justify-center space-x-10 w-full">
              <div className="w-full font-bold text-xl text-center mb-5">
                استماره تفاصيل الاصل
              </div>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                العدد
                <input
                  placeholder="العدد"
                  value={formData.quantity}
                  name="quantity"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                السعر
                <input
                  placeholder="السعر"
                  value={formData.price}
                  name="price"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                تاريخ الدفعه
                <input
                  placeholder="yyyy-mm-dd"
                  value={formData.instalmentDate}
                  name="instalmentDate"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
              </label>
            </div>

            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              <div className="w-1/2">
                <Button
                  type="submit"
                  text="تسجيل"
                  isDisabled={
                    !(
                      formData.assetId &&
                      formData.price &&
                      formData.quantity &&
                      formData.instalmentDate
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AssetDetailsForm;
