import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';

const Sell = () => {
  const [variantClient, setVariantClient] = useState([]);
  const { fabricTransactionVariantId = '' } = useParams();
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variant-client/`, {
        fabricTransactionVariantId
      });

      if (response.data.statusCode === 200) {
        setVariantClient(response.data.data);
      }
    })();
  }, [fabricTransactionVariantId]);

  const deleteVariantClient = async (variantClientId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}variant-client/delete`,
          {
            variantClientId: variantClientId
          }
        );

        if (response.data.statusCode === 200) {
          setVariantClient(
            variantClient.filter(
              (variant) => variantClientId !== variant.variantClientId
            )
          );
        }
      }
    });
  };

  let totalWithDiscount = 0;

  return (
    <div className='w-full'>
      <Header
        text='بيع'
        link={`/variant/sell/form/${fabricTransactionVariantId}`}
      />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>كود العميل</th>
            <th>اسم العميل</th>
            <th>الكميه</th>
            <th>سعر القطعه بالربح</th>
            <th>الاجمالى بالربح</th>
            <th>الاجمالى بالربح والخصم</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {variantClient.map((variantClientDetail) => {
            totalWithDiscount = variantClientDetail.finalPrice;

            if (variantClientDetail.discountType === 2) {
              totalWithDiscount =
                variantClientDetail.finalPrice -
                variantClientDetail.quantity * variantClientDetail.discount;
            }

            if (variantClientDetail.discountType === 1) {
              totalWithDiscount =
                variantClientDetail.finalPrice -
                (variantClientDetail.finalPrice *
                  variantClientDetail.discount) /
                  100;
            }

            return (
              <tr
                key={variantClientDetail.variantClientId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{variantClientDetail.clientCode}</td>
                <td>{variantClientDetail.clientName}</td>
                <td>{variantClientDetail.quantity || 0}</td>
                <td>
                  {variantClientDetail.finalPrice /
                    variantClientDetail.quantity || 0}
                </td>
                <td>{format(variantClientDetail.finalPrice || 0)}</td>
                <td>{format(totalWithDiscount || 0)}</td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/variant/sell/form/${fabricTransactionVariantId}/${variantClientDetail.variantClientId}`}
                    >
                      <img src={editIcon} alt='edit' className='inline h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteVariantClient(variantClientDetail.variantClientId)
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Sell;
