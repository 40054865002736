import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";

const FabricVariantTransactionForm = () => {
  const navigate = useNavigate();
  const { productionAssetId = "", productionAssetVariantTransactionId = null } =
    useParams();
  const [variants, setVariants] = useState([]);

  const [formData, setFormData] = useState({
    productionAssetId,
    fabricTransactionVariantId: "",
    productionAssetVariantTransactionId,
    price: 0,
    processingDate: "",
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}fabric/variants-transactions`
      );

      setVariants(response.data.data);
    })();
  }, []);

  useEffect(() => {
    if (productionAssetVariantTransactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}production-assets/variant-view-transaction`,
          {
            productionAssetVariantTransactionId,
          }
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [productionAssetVariantTransactionId]);

  useEffect(() => {
    if (productionAssetVariantTransactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}production-assets/variant-view-transaction`,
          {
            productionAssetVariantTransactionId,
          }
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [productionAssetVariantTransactionId]);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (productionAssetVariantTransactionId &&
            (await axios.post(
              `${settings.api}production-assets/update-variant-transaction`,
              formData
            ))) ||
          (await axios.post(
            `${settings.api}production-assets/create-variant-transaction`,
            formData
          ));

        if (response.data.statusCode === 200) {
          navigate(`/production-asset/variants/${productionAssetId}`);
        }
      }}
    >
      <div className="w-full lg:w-1/2">
        <div className="bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4">
          <div className="flex flex-col text-sm text-gray-500 py-4">
            <div className="flex flex-row justify-center space-x-10 w-full">
              <div className="w-full font-bold text-xl text-center mb-5">
                استماره دفعات متطلبات الانتاج
              </div>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                الاصناف
                <select
                  value={formData.fabricTransactionVariantId}
                  name="fabricTransactionVariantId"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  pattern="[0-9]{4}-[0-9]2}-[0-9]{2}"
                >
                  <option value="">اختر</option>
                  {variants.map((variant) => (
                    <option
                      key={variant.fabricTransactionVariantId}
                      value={variant.fabricTransactionVariantId}
                    >
                      {`${variant.variantCode} - ${variant.variantName}`}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                التكلفه
                <input
                  placeholder="التكلفه"
                  value={formData.price}
                  name="price"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                تاريخ الدفعه
                <input
                  placeholder="yyyy-mm-dd"
                  value={formData.processingDate}
                  name="processingDate"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
              </label>
            </div>

            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              <div className="w-1/2">
                <Button
                  type="submit"
                  text="تسجيل"
                  isDisabled={
                    !(
                      (formData.fabricTransactionVariantId ||
                        formData.productionAssetId) &&
                      formData.processingDate
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FabricVariantTransactionForm;
