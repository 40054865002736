import { Link } from "react-router-dom";

import addIcon from "../../imgs/add.png";

const Header = ({ text, link }) => {
  return (
    <>
      <div className="flex w-full font-bold cursor-pointer">
        <Link to={link} className=" bg-green-700 rounded-xl text-white p-3">
          <img src={addIcon} alt="add" className="h-5 inline ml-2" />
          {text}
        </Link>
      </div>
      <hr className="my-5" />
    </>
  );
};

export default Header;
