import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Button from '../Button';

import settings from '../../config';
import deliveryIcon from '../../imgs/delivery.png';
import sewingIcon from '../../imgs/sewing.png';
import shirtIcon from '../../imgs/t-shirt.svg';

const VariantSearch = () => {
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    variantCode: ''
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleStatusChange = async (fabricTransactionVariantId, status) => {
    let finalStatus = 0;
    switch (status) {
      case 0:
        finalStatus = 1;
        break;
      case 1:
        finalStatus = 2;
        break;
      case 2:
        finalStatus = 0;
        break;
      default:
        finalStatus = 0;
        break;
    }
    const response = await axios.post(
      `${settings.api}fabric/status-variant-transaction`,
      {
        fabricTransactionVariantId,
        status: finalStatus
      }
    );
    if (response.data.data === 1) {
      const newData = data.map((fabricTransactionVariant) => {
        if (
          fabricTransactionVariant.fabricTransactionVariantId ===
          fabricTransactionVariantId
        ) {
          return {
            ...fabricTransactionVariant,
            status: finalStatus
          };
        } else {
          return fabricTransactionVariant;
        }
      });
      setData([...newData]);
    }
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        (async () => {
          const response = await axios.post(`${settings.api}variant/code`, {
            variantCode: formData.variantCode
          });

          if (response.data.statusCode === 200) {
            setData(response.data.data);
          }
        })();
      }}
    >
      <div className='w-full'>
        <div className='bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4'>
          <div className='flex flex-col text-sm text-gray-500 py-4'>
            <div className='flex flex-row justify-center space-x-10 w-full'>
              <div className='w-full font-bold text-xl text-center mb-5'>
                بحث بكود الصنف
              </div>
            </div>
            <div className='flex space-x-10 w-1/2 justify-center items-center'>
              <label className='w-1/2'>
                <input
                  placeholder='كود الصنف'
                  value={formData.variantCode}
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  name='variantCode'
                />
              </label>
              <div className='w-1/2 text-center'>
                <div>
                  <Button
                    type='submit'
                    text='بحث'
                    isDisabled={!formData.variantCode}
                    className='mx-auto text-center w-1/2'
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-row justify-center space-x-10 w-full mt-5'>
              {data && (
                <div className='w-full'>
                  <table className='w-full table-auto border-separate border-spacing-1 text-right '>
                    <thead>
                      <tr>
                        <th>الحاله</th>
                        <th>كود الصنف</th>
                        <th>اسم الصنف</th>
                        <th>نوع الصنف</th>
                        <th>عدد القطع</th>
                        <th>مبيعات</th>
                        <th>متاح</th>
                        <th>تاريخ المعامله</th>
                        <th className='text-center w-24'>تركيب</th>
                        <th className='text-center w-24'>تفاصيل</th>
                        <th className='text-center w-24'>بيع</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((variantTransaction) => {
                        return (
                          <tr
                            key={variantTransaction.fabricTransactionVariantId}
                            className='bg-white odd:bg-gray-100 hover:bg-orange-100'
                          >
                            <td className='text-center'>
                              <div className='w-full flex flex-row items-center justify-center'>
                                {variantTransaction.status === 0 && (
                                  <div
                                    className='items-center bg-gray-500 w-5 h-5 rounded-full cursor-pointer'
                                    alt='خام'
                                    title='خام'
                                    onClick={() =>
                                      handleStatusChange(
                                        variantTransaction.fabricTransactionVariantId,
                                        0
                                      )
                                    }
                                  />
                                )}
                                {variantTransaction.status === 1 && (
                                  <div
                                    className='items-center bg-rose-500 w-5 h-5 rounded-full cursor-pointer'
                                    alt='دوره تشغيل'
                                    title='دوره تشغيل'
                                    onClick={() =>
                                      handleStatusChange(
                                        variantTransaction.fabricTransactionVariantId,
                                        1
                                      )
                                    }
                                  />
                                )}
                                {variantTransaction.status === 2 && (
                                  <div
                                    className='items-center bg-green-500 w-5 h-5 rounded-full cursor-pointer'
                                    alt='فى المخزن'
                                    title='فى المخزن'
                                    onClick={() =>
                                      handleStatusChange(
                                        variantTransaction.fabricTransactionVariantId,
                                        2
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </td>
                            <td>{variantTransaction.variantCode}</td>
                            <td>{variantTransaction.variantName}</td>
                            <td>{variantTransaction.variantType}</td>
                            <td>{variantTransaction.quantity}</td>
                            <td>{variantTransaction.sold}</td>
                            <td>
                              {variantTransaction.quantity -
                                variantTransaction.sold}
                            </td>
                            <td>{variantTransaction.processingDate}</td>
                            <td className='text-center'>
                              <div className='flex flex-col items-center w-full cursor-pointer'>
                                <div className='font-bold rounded-full w-8'>
                                  <Link
                                    to={`/variant/operations/${variantTransaction.fabricTransactionVariantId}`}
                                  >
                                    <img src={shirtIcon} alt='details' />
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className='text-center'>
                              <div className='flex flex-col items-center w-full cursor-pointer'>
                                <div className='font-bold rounded-full w-8'>
                                  <Link
                                    to={`/variant/details/${variantTransaction.fabricTransactionVariantId}`}
                                  >
                                    <img src={sewingIcon} alt='details' />
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className='text-center'>
                              <div className='flex flex-col items-center w-full cursor-pointer'>
                                <div className='font-bold rounded-full w-8'>
                                  <Link
                                    to={`/variant/sell/${variantTransaction.fabricTransactionVariantId}`}
                                  >
                                    <img src={deliveryIcon} alt='delivery' />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default VariantSearch;
