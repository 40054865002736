import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';
import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import assetsIcon from '../../imgs/assets.png';
import deleteIcon from '../../imgs/delete.png';

const Assets = () => {
  const [assets, seAssets] = useState([]);
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}assets/`);

      if (response.data.statusCode === 200) {
        seAssets(response.data.data);
      }
    })();
  }, []);

  const deleteBillCategory = async (assetId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}assets/delete`, {
          assetId
        });

        if (response.data.statusCode === 200) {
          seAssets(assets.filter((asset) => assetId !== asset.assetId));
        }
      }
    });
  };

  let total = 0;

  return (
    <div className='w-full'>
      <Header text='اضافه اصل' link='/asset/form' />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>الاصل</th>
            <th>العدد الاجمالى</th>
            <th>السعر الاجمالى</th>
            <th className='text-center w-24'>التفاصيل</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {assets.map((asset) => {
            total += asset.price || 0;

            return (
              <tr
                key={asset.assetId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{asset.assetId}</td>
                <td>{asset.assetName}</td>
                <td>{asset.quantity || 0}</td>
                <td>{asset.price || 0}</td>
                <td className='text-center'>
                  <Link
                    to={`/asset/details/${asset.assetId}`}
                    className='flex flex-col items-center w-full cursor-pointer'
                  >
                    <img
                      src={assetsIcon}
                      alt='assetsIcon'
                      className='inline h-8'
                    />
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/asset/form/${asset.assetId}`}
                      className='flex flex-col items-center w-full cursor-pointer'
                    >
                      <img src={editIcon} alt='edit' className='h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() => deleteBillCategory(asset.assetId)}
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td colSpan={3} className='text-rose-500'>
              اجمالى المتبقى
            </td>
            <td className='text-rose-500'>{format(total)}</td>
            <td colSpan={3}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Assets;
