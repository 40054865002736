import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { format } from '../../utils';
import settings from '../../config';

const DashboardContent = () => {
  const [profitDetails, setProfitDetails] = useState([]);
  const [clientsRemainingBalance, setClientsRemainingBalance] = useState([]);
  const [merchantsRemainingBalance, setMerchantsRemainingBalance] = useState(
    []
  );
  const [
    accessoriesMerchantsRemainingBalance,
    setAccessoriesMerchantsRemainingBalance
  ] = useState([]);
  const [fabricDetails, setFabricDetails] = useState([]);
  const [accessoriesDetails, setAccessoriesDetails] = useState([]);
  const [variantsDetails, setVariantsDetails] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}dashboard/profit`);

      if (response.data.statusCode === 200) {
        setProfitDetails(response.data.data);
      }

      const clientsRemainingBalanceResponse = await axios.post(
        `${settings.api}dashboard/clients`
      );

      if (response.data.statusCode === 200) {
        setClientsRemainingBalance(clientsRemainingBalanceResponse.data.data);
      }

      const merchantsRemainingBalanceResponse = await axios.post(
        `${settings.api}dashboard/merchants`
      );

      if (response.data.statusCode === 200) {
        setMerchantsRemainingBalance(
          merchantsRemainingBalanceResponse.data.data
        );
      }

      const accessoriesMerchantsRemainingBalanceResponse = await axios.post(
        `${settings.api}dashboard/accessories-merchants`
      );

      if (response.data.statusCode === 200) {
        setAccessoriesMerchantsRemainingBalance(
          accessoriesMerchantsRemainingBalanceResponse.data.data
        );
      }

      const fabricDetailsResponse = await axios.post(
        `${settings.api}dashboard/fabric-details`
      );

      if (response.data.statusCode === 200) {
        setFabricDetails(fabricDetailsResponse.data.data);
      }

      const accessoriesDetailsResponse = await axios.post(
        `${settings.api}dashboard/accessories-details`
      );

      if (response.data.statusCode === 200) {
        setAccessoriesDetails(accessoriesDetailsResponse.data.data);
      }

      const variantsDetailsResponse = await axios.post(
        `${settings.api}dashboard/variants-details`
      );

      if (response.data.statusCode === 200) {
        setVariantsDetails(variantsDetailsResponse.data.data);
      }
    })();
  }, []);

  return (
    <div className='flex gap-x-8 gap-y-8 flex-wrap'>
      <div className='border p-10 bg-green-100 rounded-lg'>
        <div className='text-center'>الربح</div>
        <div className='text-xl text-center'>
          {format(profitDetails[0]?.profit)}
        </div>
      </div>
      <div className='border p-10 bg-rose-100 rounded-lg'>
        <div className='text-center'>المتبقى علي العملاء</div>
        <div className='text-xl text-center'>
          {format(clientsRemainingBalance[0]?.balance)}
        </div>
      </div>
      <div className='border p-10 bg-rose-100 rounded-lg'>
        <div className='text-center'>المتبقى من تجار الخام</div>
        <div className='text-xl text-center'>
          {format(merchantsRemainingBalance[0]?.balance)}
        </div>
      </div>
      <div className='border p-10 bg-rose-100 rounded-lg'>
        <div className='text-center'>المتبقى من تجار الاكسسوارات</div>
        <div className='text-xl text-center'>
          {format(accessoriesMerchantsRemainingBalance[0]?.balance)}
        </div>
      </div>

      <div className='border p-10 bg-rose-100 rounded-lg'>
        <div className='text-center'>سعر الخام المتبقى</div>
        <div className='text-xl text-center'>
          {format(fabricDetails[0]?.totalPrice)}
        </div>
      </div>

      <div className='border p-10 bg-rose-100 rounded-lg'>
        <div className='text-center'>سعر الاكسسوارات المتبقى</div>
        <div className='text-xl text-center'>
          {format(accessoriesDetails[0]?.totalPrice)}
        </div>
      </div>

      <div className='border p-10 bg-rose-100 rounded-lg'>
        <div className='text-center'>سعر الاصناف المتبقى</div>
        <div className='text-xl text-center'>
          {format(variantsDetails[0]?.totalPrice)}
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
