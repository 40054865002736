import { Routes, Route, Link } from 'react-router-dom';

import Login from '../Login';
import Dashboard from '../Dashboard';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const Layout = () => {
  return (
    <div className={`min-h-full h-full flex flex-col`}>
      <div className='flex flex-row items-center p-4 print:hidden'>
        <Link to='/dashboard'>الورشه</Link>
      </div>
      <ScrollToTop />
      <div className='flex flex-col w-full h-full'>
        <Routes>
          <Route path='/' exact element={<Login />} />
          <Route path='/*' exact element={<Dashboard />} />
        </Routes>
      </div>
    </div>
  );
};

export default Layout;
