import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';

const FabricInstalments = () => {
  const [fabricInstalments, setFabricInstalments] = useState([]);
  const { merchantId } = useParams();
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabric/instalments`, {
        merchantId
      });

      if (response.data.statusCode === 200) {
        setFabricInstalments(response.data.data);
      }
    })();
  }, [merchantId]);

  const deleteProductInstalment = async (fabricInstalmentId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}fabric/delete-instalment`,
          {
            fabricInstalmentId
          }
        );

        if (response.data.statusCode === 200) {
          setFabricInstalments(
            fabricInstalments.filter(
              (fabricInstalment) =>
                fabricInstalmentId !== fabricInstalment.fabricInstalmentId
            )
          );
        }
      }
    });
  };

  let totalPaid = 0;

  return (
    <div className='w-full'>
      <Header
        text='اضافه دفعه'
        link={`/fabric/instalments/form/${merchantId}`}
      />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>اسم التاجر</th>
            <th>المبلغ الاجمالى</th>
            <th>قيمه الدفعه</th>
            <th>خصم</th>
            <th>المبلغ المتبقى</th>
            <th>تاريخ الدفعه</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {fabricInstalments.map((fabricInstalment) => {
            totalPaid += fabricInstalment.totalPaid + fabricInstalment.discount;

            return (
              <tr
                key={fabricInstalment.fabricInstalmentId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{fabricInstalment.merchantName}</td>
                <td>{format(fabricInstalment.amount || 0)}</td>
                <td>{format(fabricInstalment.totalPaid || 0)}</td>
                <td>{format(fabricInstalment.discount || 0)}</td>
                <td>{format(fabricInstalment.amount - totalPaid || 0)}</td>
                <td>{fabricInstalment.instalmentDate}</td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/fabric/instalments/form/${merchantId}/${fabricInstalment.fabricInstalmentId}`}
                      className='flex flex-col items-center w-full cursor-pointer'
                    >
                      <img src={editIcon} alt='edit' className='h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteProductInstalment(
                          fabricInstalment.fabricInstalmentId
                        )
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricInstalments;
