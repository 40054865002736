import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Button from '../Button';

import settings from '../../config';

const AccessoryForm = () => {
  const navigate = useNavigate();
  const { accessoryId } = useParams();

  const [formData, setFormData] = useState({
    accessoryName: '',
    finalPrice: ''
  });

  useEffect(() => {
    if (accessoryId) {
      (async () => {
        const response = await axios.post(`${settings.api}accessories/view`, {
          accessoryId: accessoryId
        });

        if (response.data.data) {
          setFormData(response.data.data[0]);
        }
      })();
    }
  }, [accessoryId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}accessories/${accessoryId ? 'update' : 'create'}`,
          formData
        );

        if (response.data.statusCode === 200) {
          navigate('/accessories');
        }
      }}
    >
      <div className='w-full lg:w-1/2'>
        <div className='bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4'>
          <div className='flex flex-col text-sm text-gray-500 py-4'>
            <div className='flex flex-row justify-center space-x-10 w-full'>
              <div className='w-full font-bold text-xl text-center mb-5'>
                استماره الاكسسوار
              </div>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                اسم الاكسسوار
                <input
                  placeholder='اسم الاكسسوار'
                  value={formData.accessoryName}
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  name='accessoryName'
                />
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                السعر
                <input
                  placeholder='السعر'
                  value={formData.finalPrice}
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  name='finalPrice'
                />
              </label>
            </div>
            <div className='flex flex-row justify-center space-x-10 w-full mt-5'>
              <div className='w-1/2'>
                <Button
                  type='submit'
                  text='تسجيل'
                  isDisabled={!formData.accessoryName}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccessoryForm;
