import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import { format } from '../../utils';
import buy from '../../imgs/buy.png';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';
import scissorsIcon from '../../imgs/scissors.png';

const Fabrics = () => {
  const [fabrics, seFabrics] = useState([]);
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabrics/`);

      if (response.data.statusCode === 200) {
        seFabrics(response.data.data);
      }
    })();
  }, []);

  const deleteFabric = async (fabricId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}fabrics/delete`, {
          fabricId
        });

        if (response.data.statusCode === 200) {
          seFabrics(fabrics.filter((fabric) => fabricId !== fabric.fabricId));
        }
      }
    });
  };

  return (
    <div className='w-full'>
      <Header text='اضافه خامه' link='/fabric/form' />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>اسم الخامه</th>
            <th>السعر الاجمالى</th>
            <th>الوزن الاجمالى</th>
            <th>الوزن المتاح</th>
            <th className='text-center w-24'>شراء</th>
            <th className='text-center w-24'>قص</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {fabrics.map((fabric) => {
            return (
              <tr
                key={fabric.fabricId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{fabric.fabricId}</td>
                <td>{fabric.fabricName}</td>
                <td>{format(fabric.price || 0)}</td>
                <td>{(fabric.totalWeight || 0).toLocaleString('en-US')}</td>
                <td>{(fabric.weight || 0).toLocaleString('en-US')}</td>
                <td className='text-center'>
                  <Link
                    className='cursor-pointer'
                    to={`/fabric/transactions/${fabric.fabricId}`}
                  >
                    <img src={buy} alt='in-out' className='inline h-8' />
                  </Link>
                </td>
                <td className='text-center'>
                  <Link
                    className='cursor-pointer'
                    to={`/fabric/cuts/${fabric.fabricId}`}
                  >
                    <img
                      src={scissorsIcon}
                      alt='scissors'
                      className='inline h-12'
                    />
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link to={`/fabric/form/${fabric.fabricId}`}>
                      <img src={editIcon} alt='edit' className='inline h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() => deleteFabric(fabric.fabricId)}
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Fabrics;
