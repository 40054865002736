import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';
import shirtIcon from '../../imgs/t-shirt.svg';

const FabricVariantTransactions = () => {
  const [fabricVariants, setFabricInstalments] = useState([]);
  const { fabricTransactionId } = useParams();
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}fabric/variant-transactions`,
        {
          fabricTransactionId
        }
      );

      if (response.data.statusCode === 200) {
        setFabricInstalments(response.data.data);
      }
    })();
  }, [fabricTransactionId]);

  const deleteProductInstalment = async (fabricTransactionVariantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}fabric/delete-variant-transaction`,
          {
            fabricTransactionVariantId
          }
        );

        if (response.data.statusCode === 200) {
          setFabricInstalments(
            fabricVariants.filter(
              (fabricVariant) =>
                fabricTransactionVariantId !==
                fabricVariant.fabricTransactionVariantId
            )
          );
        }
      }
    });
  };

  return (
    <div className='w-full'>
      <Header
        text='اضافه منتجات'
        link={`/fabric/variant/form/${fabricTransactionId}`}
      />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>الحاله</th>
            <th>مسلسل</th>
            <th>كود المنتج</th>
            <th>اسم المنتج</th>
            <th>نوع المنتج</th>
            <th>العدد</th>
            <th>السعر بالقطعه</th>
            <th>السعر الاجمالى</th>
            <th>تاريخ الدفعه</th>
            <th className='text-center w-24'>تركيب</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {fabricVariants.map((fabricVariant) => {
            return (
              <tr
                key={fabricVariant.fabricTransactionVariantId}
                className={`bg-white odd:bg-gray-100 hover:bg-orange-100`}
              >
                <td className='text-center'>
                  <div className='w-full flex flex-row items-center justify-center'>
                    {fabricVariant.status === 1 && (
                      <div
                        className='items-center bg-rose-500 w-5 h-5 rounded-full'
                        alt='دوره تشغيل'
                        title='دوره تشغيل'
                      />
                    )}
                    {fabricVariant.status === 2 && (
                      <div
                        className='items-center bg-green-500 w-5 h-5 rounded-full'
                        alt='فى المخزن'
                        title='فى المخزن'
                      />
                    )}
                  </div>
                </td>
                <td>{fabricVariant.fabricTransactionVariantId}</td>
                <td>{fabricVariant.variantCode}</td>
                <td>{fabricVariant.variantName}</td>
                <td>{fabricVariant.variantType}</td>
                <td>{(fabricVariant.quantity || 0).toLocaleString('en-US')}</td>
                <td>{format(fabricVariant.price || 0)}</td>
                <td>
                  {format(fabricVariant.price * fabricVariant.quantity || 0)}
                </td>
                <td>{fabricVariant.processingDate}</td>
                <td className='text-center'>
                  <div className='flex flex-col items-center w-full cursor-pointer'>
                    <div className='font-bold rounded-full w-8'>
                      <Link
                        to={`/variant/operations/${fabricVariant.fabricTransactionVariantId}`}
                      >
                        <img src={shirtIcon} alt='details' />
                      </Link>
                    </div>
                  </div>
                </td>
                {admin.canEdit === 1 && (
                  <td className={`text-center`}>
                    <Link
                      to={`/fabric/variant/form/${fabricTransactionId}/${fabricVariant.fabricTransactionVariantId}`}
                      className='flex flex-col items-center w-full cursor-pointer'
                    >
                      <img src={editIcon} alt='edit' className='h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className={`text-center`}>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteProductInstalment(
                          fabricVariant.fabricTransactionVariantId
                        )
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricVariantTransactions;
