import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ModalProvider } from './context';
import Layout from './components/Layout';
import './output.css';

function App() {
  return (
    <div className='h-full bg-cover'>
      <BrowserRouter>
        <ModalProvider>
          <Routes>
            <Route path='/*' element={<Layout />} />
          </Routes>
        </ModalProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
