import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';

const AccessoryTransactions = () => {
  const [accessoryTransactions, setAccessoryTransactions] = useState([]);
  const { accessoryId } = useParams();
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}accessory/transactions`,
        {
          accessoryId
        }
      );

      if (response.data.statusCode === 200) {
        setAccessoryTransactions(response.data.data);
      }
    })();
  }, [accessoryId]);

  const deleteAccessoryTransaction = async (accessoryTransactionId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}accessory/delete-transaction`,
          {
            accessoryTransactionId
          }
        );

        if (response.data.statusCode === 200) {
          setAccessoryTransactions(
            accessoryTransactions.filter(
              (accessoryTransaction) =>
                accessoryTransactionId !==
                accessoryTransaction.accessoryTransactionId
            )
          );
        }
      }
    });
  };

  return (
    <div className='w-full'>
      <Header
        text='اضافه معامله اكسسوار'
        link={`/accessory/transactions/form/${accessoryId}`}
      />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>اسم الاكسسوار</th>
            <th>اسم تاجر الاكسسوار</th>
            <th>سعر القطعه</th>
            <th>عدد القطع</th>
            <th>السعر الاجمالى</th>
            <th>تاريخ المعامله</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {accessoryTransactions.map((accessoryTransaction) => {
            return (
              <tr
                key={accessoryTransaction.accessoryTransactionId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{accessoryTransaction.accessoryName}</td>
                <td>{accessoryTransaction.accessoriesMerchantName}</td>
                <td>{format(accessoryTransaction.price || 0)}</td>
                <td>{accessoryTransaction.weight || 0}</td>
                <td>
                  {format(
                    accessoryTransaction.price * accessoryTransaction.weight ||
                      0
                  )}
                </td>
                <td>{accessoryTransaction.transactionDate}</td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/accessory/transactions/form/${accessoryId}/${accessoryTransaction.accessoryTransactionId}`}
                    >
                      <img src={editIcon} alt='edit' className='inline h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteAccessoryTransaction(
                          accessoryTransaction.accessoryTransactionId
                        )
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AccessoryTransactions;
