import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';
import settings from '../../config';
import instalment from '../../imgs/instalment.png';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';

const BillCategories = () => {
  const [billCategories, seBillCategories] = useState([]);
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}bill-categories/`);

      if (response.data.statusCode === 200) {
        seBillCategories(response.data.data);
      }
    })();
  }, []);

  const deleteBillCategory = async (billCategoryId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}bill-categories/delete`,
          {
            billCategoryId
          }
        );

        if (response.data.statusCode === 200) {
          seBillCategories(
            billCategories.filter(
              (billCategory) => billCategoryId !== billCategory.billCategoryId
            )
          );
        }
      }
    });
  };

  return (
    <div className='w-full'>
      <Header text='اضافه قسم مدفوعات' link='/bill-category/form' />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>اسم قسم المدفوعات</th>
            <th>اجمالى المدفوع</th>
            <th className='text-center w-24'>الدفعات</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {billCategories.map((billCategory) => {
            return (
              <tr
                key={billCategory.billCategoryId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{billCategory.billCategoryId}</td>
                <td>{billCategory.billCategoryName}</td>
                <td>{billCategory.amount || 0}</td>
                <td className='text-center'>
                  <Link
                    to={`/bill-category/instalments/${billCategory.billCategoryId}`}
                    className='flex flex-col items-center w-full cursor-pointer'
                  >
                    <img
                      src={instalment}
                      alt='instalment'
                      className='inline h-8'
                    />
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/bill-category/form/${billCategory.billCategoryId}`}
                      className='flex flex-col items-center w-full cursor-pointer'
                    >
                      <img src={editIcon} alt='edit' className='h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteBillCategory(billCategory.billCategoryId)
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BillCategories;
