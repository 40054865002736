import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import settings from '../../config';

const VariantDetails = () => {
  const { fabricTransactionVariantId } = useParams();

  const [variantDetails, setVariantDetails] = useState([]);

  useEffect(() => {
    if (!variantDetails?.length) {
      (async () => {
        const response = await axios.post(
          `${settings.api}fabric/variant-transaction-details`,
          {
            fabricTransactionVariantId
          }
        );

        setVariantDetails(response.data.data);
      })();
    }
  }, [fabricTransactionVariantId, variantDetails?.length]);

  const fabric =
    variantDetails.find((variantDetail) => variantDetail.type === 'fabric') ||
    {};
  const accessories =
    variantDetails.filter(
      (variantDetail) => variantDetail.type === 'accessory'
    ) || [];
  const productionAssets =
    variantDetails.filter(
      (variantDetail) => variantDetail.type === 'production_asset'
    ) || [];

  const accessoriesTotalPrice = accessories.reduce((acc, accessory) => {
    return accessory.price * accessory.quantity + acc;
  }, 0);

  const productionAssetsTotalPrice = productionAssets.reduce(
    (acc, productionAsset) => {
      return productionAsset.price + acc;
    },
    0
  );

  return (
    <div className='w-full lg:w-1/2'>
      <div className='bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4'>
        <div className='flex flex-col text-sm text-gray-500 py-4'>
          <div className='flex flex-row justify-center space-x-10 w-full'>
            <div className='w-full font-bold text-xl text-center mb-5'>
              {fabric.variantName}
            </div>
          </div>
          <div className='w-full'>
            <div className='text-right w-full m-5'>
              اجمالى عدد القطع: {fabric.quantity}
            </div>
            <div className='text-right w-full m-5 text-green-500'>
              سعر القطعه الواحده:{' '}
              {fabric.price +
                accessoriesTotalPrice +
                productionAssetsTotalPrice}
            </div>
            <div className='text-right w-full m-5 text-red-500'>
              اجمالى الطلبيه:{' '}
              {(fabric.price +
                accessoriesTotalPrice +
                productionAssetsTotalPrice) *
                fabric.quantity}
            </div>
            <div className='text-center w-full m-5'>الخامات</div>
            <table className='table-auto w-full text-center'>
              <thead>
                <tr>
                  <th>الخامه</th>
                  <th>عدد الوحدات</th>
                  <th className='text-rose-500'>سعر الخامه للقطعه</th>
                </tr>
              </thead>
              <tbody>
                <tr className='bg-slate-50'>
                  <td>{fabric.fabricName}</td>
                  <td>{fabric.quantity}</td>
                  <td className='text-rose-500'>{fabric.price || 0}</td>
                </tr>
              </tbody>
            </table>
            <div className='text-center w-full m-5'>الاكسسوارات</div>
            <table className='table-auto w-full text-center'>
              <thead>
                <tr>
                  <th>الاكسسوار</th>
                  <th>عدد الوحدات</th>
                  <th>السعر للقطعه</th>
                </tr>
              </thead>
              <tbody>
                {accessories.map((accessory) => (
                  <tr
                    className='bg-slate-50'
                    key={`accessory-${accessory.accessoryName}`}
                  >
                    <td>{accessory.accessoryName}</td>
                    <td>{accessory.quantity}</td>
                    <td>{accessory.price}</td>
                  </tr>
                ))}
                <tr className='text-rose-500'>
                  <td>الاجمالى</td>
                  <td></td>
                  <td>{accessoriesTotalPrice}</td>
                </tr>
              </tbody>
            </table>
            <div className='text-center w-full m-5'>متطلبات انتاج</div>
            <table className='table-auto w-full text-center'>
              <thead>
                <tr>
                  <th>الثابت</th>
                  <th>السعر للقطعه</th>
                </tr>
              </thead>
              <tbody>
                {productionAssets.map((productionAsset) => (
                  <tr
                    className='bg-slate-50'
                    key={`productionAsset-${productionAsset.productionAssetName}`}
                  >
                    <td>{productionAsset.productionAssetName}</td>
                    <td>{productionAsset.price}</td>
                  </tr>
                ))}
                <tr className='text-rose-500'>
                  <td>الاجمالى</td>
                  <td>{productionAssetsTotalPrice}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariantDetails;
