import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';
import settings from '../../config';
import deleteIcon from '../../imgs/delete.png';
import editIcon from '../../imgs/edit.png';
import Header from '../Header';

const BillCategoryInstalments = () => {
  const [billCategoryInstalments, setBillCategoryInstalments] = useState([]);
  const { billCategoryId } = useParams();
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}bill-categories/instalments`,
        {
          billCategoryId
        }
      );

      if (response.data.statusCode === 200) {
        setBillCategoryInstalments(response.data.data);
      }
    })();
  }, [billCategoryId]);

  const deleteBillCategoryInstalment = async (billId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}bill-categories/delete-instalment`,
          {
            billId
          }
        );

        if (response.data.statusCode === 200) {
          setBillCategoryInstalments(
            billCategoryInstalments.filter(
              (billCategoryInstalment) =>
                billId !== billCategoryInstalment.billId
            )
          );
        }
      }
    });
  };

  let total = 0;

  return (
    <div className='w-full'>
      <Header
        text='اضافه دفعه'
        link={`/bill-category/instalments/form/${billCategoryId}`}
      />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>اسم قسم المدفوعات</th>
            <th>قيمه الدفعه</th>
            <th>تاريخ الدفعه</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {billCategoryInstalments.map((billCategoryInstalment) => {
            total +=
              billCategoryInstalment.amount * billCategoryInstalment.billType;

            return (
              <tr
                key={billCategoryInstalment.billId}
                className='bg-white odd:bg-gray-100'
              >
                <td>{billCategoryInstalment.billCategoryName}</td>
                <td
                  className={
                    (billCategoryInstalment.billType > 0 && 'text-green-500') ||
                    'text-rose-500'
                  }
                >
                  {billCategoryInstalment.amount}
                </td>
                <td>{billCategoryInstalment.instalmentDate}</td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/bill-category/instalments/form/${billCategoryId}/${billCategoryInstalment.billId}`}
                      className='flex flex-col items-center w-full cursor-pointer'
                    >
                      <img src={editIcon} alt='edit' className='h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteBillCategoryInstalment(
                          billCategoryInstalment.billId
                        )
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td className='text-rose-500'>اجمالى المدفوع</td>
            <td className='text-rose-500'>{total}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BillCategoryInstalments;
