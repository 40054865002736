import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';

const VariantInstalment = () => {
  const [variantClientInstalments, setVariantClientInstalments] = useState([]);
  const { clientId = '' } = useParams();
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}variant-client/instalments`,
        {
          clientId
        }
      );

      if (response.data.statusCode === 200) {
        setVariantClientInstalments(response.data.data);
      }
    })();
  }, [clientId]);

  const deleteVariantClientInstalment = async (variantClientInstalmentId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}variant-client/delete-instalment`,
          {
            variantClientInstalmentId: variantClientInstalmentId
          }
        );

        if (response.data.statusCode === 200) {
          setVariantClientInstalments(
            variantClientInstalments.filter(
              (variant) =>
                variantClientInstalmentId !== variant.variantClientInstalmentId
            )
          );
        }
      }
    });
  };

  let totalPaid = 0;

  return (
    <div className='w-full'>
      <Header
        text='استلام دفعه'
        link={`/variant/instalments/form/${clientId}`}
      />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>كود العميل</th>
            <th>اسم العميل</th>
            <th>المبلغ الاجمالى</th>
            <th>قيمه الدفعه</th>
            <th>خصم</th>
            <th>المتبقى</th>
            <th>تاريخ الدفعه</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {variantClientInstalments.map((variantClientDetail) => {
            totalPaid +=
              variantClientDetail.paid + variantClientDetail.discount;
            return (
              <tr
                key={variantClientDetail.variantClientId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{variantClientDetail.clientCode}</td>
                <td>{variantClientDetail.clientName}</td>
                <td>{format(variantClientDetail.amount || 0)}</td>
                <td>{format(variantClientDetail.paid || 0)}</td>
                <td>{format(variantClientDetail.discount || 0)}</td>
                <td>{format(variantClientDetail.amount - totalPaid || 0)}</td>
                <td>{variantClientDetail.instalmentDate}</td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/variant/instalments/form/${clientId}/${variantClientDetail.variantClientInstalmentId}`}
                    >
                      <img src={editIcon} alt='edit' className='inline h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteVariantClientInstalment(
                          variantClientDetail.variantClientInstalmentId
                        )
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}{' '}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VariantInstalment;
