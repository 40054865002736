import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";

const BillCategoryForm = () => {
  const navigate = useNavigate();
  const { billCategoryId } = useParams();

  const [formData, setFormData] = useState({
    billCategoryName: "",
  });

  useEffect(() => {
    if (billCategoryId) {
      (async () => {
        const response = await axios.post(`${settings.api}bill-categories/view`, {
          billCategoryId: billCategoryId,
        });

        if (response.data.data) {
          setFormData(response.data.data[0]);
        }
      })();
    }
  }, [billCategoryId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}bill-categories/${billCategoryId ? "update" : "create"}`,
          formData
        );

        if (response.data.statusCode === 200) {
          navigate("/bill-categories");
        }
      }}
    >
      <div className="w-full lg:w-1/2">
        <div className="bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4">
          <div className="flex flex-col text-sm text-gray-500 py-4">
            <div className="flex flex-row justify-center space-x-10 w-full">
              <div className="w-full font-bold text-xl text-center mb-5">
                استماره قسم المدفوعات
              </div>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                اسم قسم المدفوعات
                <input
                  placeholder="اسم قسم المدفوعات"
                  value={formData.billCategoryName}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  name="billCategoryName"
                />
              </label>
            </div>
            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              <div className="w-1/2">
                <Button
                  type="submit"
                  text="تسجيل"
                  isDisabled={!formData.billCategoryName}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BillCategoryForm;
