import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

import useLocalStorage from '../../hooks/useLocalStorage';

import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';

const AccessoriesMerchantDetails = () => {
  const [accessoriesMerchantDetails, setAccessoriesMerchantDetails] = useState(
    []
  );
  const { accessoriesMerchantId = '' } = useParams();
  const [admin] = useLocalStorage('admin');

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}accessories-merchants/details`,
        {
          accessoriesMerchantId
        }
      );
      if (response.data.statusCode === 200) {
        setAccessoriesMerchantDetails(response.data.data);
      }
    })();
  }, [accessoriesMerchantId]);

  return (
    <div className='w-full'>
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>اسم الاكسسوار</th>
            <th>الوزن</th>
            <th>الاجمالى</th>
            <th>تاريخ الشراء</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
          </tr>
        </thead>
        <tbody>
          {accessoriesMerchantDetails.map((accessoriesMerchantDetail) => {
            return (
              <tr
                key={accessoriesMerchantDetail.accessoryTransactionId}
                className={`bg-white hover:bg-orange-100 odd:bg-gray-100`}
              >
                <td>{accessoriesMerchantDetail.accessoryTransactionId}</td>
                <td className='text-center'>
                  {accessoriesMerchantDetail.accessoryName}
                </td>
                <td>{accessoriesMerchantDetail.weight}</td>
                <td>{format(accessoriesMerchantDetail.totalPrice || 0)}</td>
                <td>{accessoriesMerchantDetail.transactionDate}</td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <div className='flex flex-col items-center w-full cursor-pointer'>
                      <div className='font-bold rounded-full w-8'>
                        <Link
                          to={`/accessory/transactions/form/${accessoriesMerchantDetail.accessoryId}/${accessoriesMerchantDetail.accessoryTransactionId}?from=accessories-merchant/details/${accessoriesMerchantId}`}
                        >
                          <img src={editIcon} alt='delivery' />
                        </Link>
                      </div>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AccessoriesMerchantDetails;
