import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import settings from '../../config';
import deliveryIcon from '../../imgs/delivery.png';
import sewingIcon from '../../imgs/sewing.png';
import shirtIcon from '../../imgs/t-shirt.svg';

const VariantTransactions = () => {
  const [variantTransactions, setVariantTransactions] = useState([]);
  const { variantId } = useParams();

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variant/transactions`, {
        variantId
      });

      if (response.data.statusCode === 200) {
        setVariantTransactions(response.data.data);
      }
    })();
  }, [variantId]);

  return (
    <div className='w-full'>
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>كود الصنف</th>
            <th>اسم الصنف</th>
            <th>نوع الصنف</th>
            <th>عدد القطع</th>
            <th>مبيعات</th>
            <th>متاح</th>
            <th>تاريخ المعامله</th>
            <th className='text-center w-24'>تركيب</th>
            <th className='text-center w-24'>تفاصيل</th>
            <th className='text-center w-24'>بيع</th>
          </tr>
        </thead>
        <tbody>
          {variantTransactions.map((variantTransaction) => {
            return (
              <tr
                key={variantTransaction.fabricTransactionVariantId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{variantTransaction.variantCode}</td>
                <td>{variantTransaction.variantName}</td>
                <td>{variantTransaction.variantType}</td>
                <td>
                  {(variantTransaction.quantity || 0).toLocaleString('en-US')}
                </td>
                <td>
                  {(variantTransaction.sold || 0).toLocaleString('en-US')}
                </td>
                <td>
                  {(
                    (variantTransaction.quantity || 0) -
                    (variantTransaction.sold || 0)
                  ).toLocaleString('en-US')}
                </td>
                <td>{variantTransaction.processingDate}</td>
                <td className='text-center'>
                  <div className='flex flex-col items-center w-full cursor-pointer'>
                    <div className='font-bold rounded-full w-8'>
                      <Link
                        to={`/variant/operations/${variantTransaction.fabricTransactionVariantId}`}
                      >
                        <img src={shirtIcon} alt='details' />
                      </Link>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <div className='flex flex-col items-center w-full cursor-pointer'>
                    <div className='font-bold rounded-full w-8'>
                      <Link
                        to={`/variant/details/${variantTransaction.fabricTransactionVariantId}`}
                      >
                        <img src={sewingIcon} alt='details' />
                      </Link>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <div className='flex flex-col items-center w-full cursor-pointer'>
                    <div className='font-bold rounded-full w-8'>
                      <Link
                        to={`/variant/sell/${variantTransaction.fabricTransactionVariantId}`}
                      >
                        <img src={deliveryIcon} alt='delivery' />
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VariantTransactions;
