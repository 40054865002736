import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import { format } from '../../utils';
import instalment from '../../imgs/instalment.png';
import deleteIcon from '../../imgs/delete.png';
import editIcon from '../../imgs/edit.png';

const FabricTransactions = () => {
  const [fabricTransactions, setFabricTransactions] = useState([]);
  const { fabricId } = useParams();
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabric/transactions`, {
        fabricId,
        transactionType: 1
      });

      if (response.data.statusCode === 200) {
        setFabricTransactions(response.data.data);
      }
    })();
  }, [fabricId]);

  const deleteFabricTransaction = async (fabricTransactionId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}fabric/delete-transaction`,
          {
            fabricTransactionId
          }
        );

        if (response.data.statusCode === 200) {
          setFabricTransactions(
            fabricTransactions.filter(
              (fabricTransaction) =>
                fabricTransactionId !== fabricTransaction.fabricTransactionId
            )
          );
        }
      }
    });
  };

  return (
    <div className='w-full'>
      <Header
        text='شراء خامات'
        link={`/fabric/transactions/form/${fabricId}`}
      />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>اسم الخامه</th>
            <th>اسم التاجر</th>
            <th>سعر الكيلو</th>
            <th>الوزن</th>
            <th>السعر الاجمالى</th>
            <th>المدفوع</th>
            <th>المتبقى</th>
            <th>تاريخ الشراء</th>
            <th className='text-center w-24'>الدفعات</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {fabricTransactions.map((fabricTransaction) => {
            return (
              <tr
                key={fabricTransaction.fabricTransactionId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{fabricTransaction.fabricTransactionId}</td>
                <td>{fabricTransaction.fabricName}</td>
                <td>{fabricTransaction.merchantName}</td>
                <td>{format(fabricTransaction.price || 0)}</td>
                <td>
                  {(fabricTransaction.weight || 0).toLocaleString('en-US')}
                </td>
                <td
                  className={`${
                    fabricTransaction.transactionType === 1 &&
                    ((fabricTransaction.totalPaid ===
                      fabricTransaction.price * fabricTransaction.weight &&
                      'text-green-500') ||
                      'text-rose-500')
                  }`}
                >
                  {format(
                    fabricTransaction.price * fabricTransaction.weight || 0
                  )}
                </td>
                <td>{format(fabricTransaction.totalPaid || 0)}</td>
                <td>
                  {format(
                    fabricTransaction.price * fabricTransaction.weight -
                      fabricTransaction.totalPaid || 0
                  )}
                </td>
                <td>{fabricTransaction.transactionDate}</td>
                <td className='text-center'>
                  <div className='flex flex-col items-center w-full cursor-pointer'>
                    <div className='font-bold rounded-full w-8'>
                      {(fabricTransaction.transactionType === 1 && (
                        <Link
                          to={`/fabric/instalments/${fabricTransaction.fabricTransactionId}`}
                        >
                          <img
                            src={instalment}
                            alt='instalment'
                            className='h-6'
                          />
                        </Link>
                      )) ||
                        '-'}
                    </div>
                  </div>
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/fabric/transactions/form/${fabricId}/${fabricTransaction.fabricTransactionId}`}
                      className='flex flex-col items-center w-full cursor-pointer'
                    >
                      <img src={editIcon} alt='edit' className='h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteFabricTransaction(
                          fabricTransaction.fabricTransactionId
                        )
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricTransactions;
