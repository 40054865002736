import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";

const AccessoryInstalmentForm = () => {
  const navigate = useNavigate();
  const { accessoryId = "", accessoriesMerchantId = "", accessoryInstalmentId = "" } =
    useParams();

  const [formData, setFormData] = useState({
    accessoriesMerchantId,
    accessoryId,
    amount: 0,
    discount: 0,
    instalmentDate: "",
    paymentMethod: "",
  });

  useEffect(() => {
    if (accessoryInstalmentId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}accessory/view-instalment`,
          {
            accessoryInstalmentId,
          }
        );

        setFormData({
          accessoriesMerchantId,
          ...response.data.data[0],
        });
      })();
    }
  }, [accessoryInstalmentId, accessoriesMerchantId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = accessoryInstalmentId ? await axios.post(
          `${settings.api}accessory/update-instalment`,
          formData
        ) : await axios.post(
          `${settings.api}accessory/create-instalment`,
          formData
        );

        if (response.data.statusCode === 200) {
          navigate(`/accessory/instalments/${accessoriesMerchantId}`);
        }
      }}
    >
      <div className="w-full lg:w-1/2">
        <div className="bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4">
          <div className="flex flex-col text-sm text-gray-500 py-4">
            <div className="flex flex-row justify-center space-x-10 w-full">
              <div className="w-full font-bold text-xl text-center mb-5">
                استماره دفعات الاكسسوارات
              </div>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                الدفعه
                <input
                  placeholder="الدفعه"
                  value={formData.amount}
                  name="amount"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                الخصم
                <input
                  placeholder="الخصم"
                  value={formData.discount}
                  name="discount"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                تاريخ الدفعه
                <input
                  placeholder="yyyy-mm-dd"
                  value={formData.instalmentDate}
                  name="instalmentDate"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                طريقه الدفع
                <select
                  value={formData.paymentMethod}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  name="paymentMethod"
                >
                  <option value="">اختر</option>
                  <option value="نقدى">نقدى</option>
                  <option value="فودافون كاش">فودافون كاش</option>
                  <option value="اتصالات كاش">اتصالات كاش</option>
                  <option value="بوسطه">بوسطه</option>
                  <option value="بنك">بنك</option>
                  <option value="شيك">شيك</option>
                </select>
              </label>
            </div>

            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              <div className="w-1/2">
                <Button
                  type="submit"
                  text="تسجيل"
                  isDisabled={
                    !(
                      formData.accessoriesMerchantId &&
                      formData.amount &&
                      formData.instalmentDate &&
                      formData.paymentMethod
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccessoryInstalmentForm;
