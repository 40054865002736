import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';
import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';
import invoiceIcon from '../../imgs/invoice.png';
import instalmentIcon from '../../imgs/instalment.png';

const Merchants = () => {
  const [merchants, setMerchants] = useState([]);
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}merchants/`);

      if (response.data.statusCode === 200) {
        setMerchants(response.data.data);
      }
    })();
  }, []);

  const deleteMerchant = async (merchantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}merchants/delete`, {
          merchantId
        });

        if (response.data.statusCode === 200) {
          setMerchants(
            merchants.filter((merchant) => merchantId !== merchant.merchantId)
          );
        }
      }
    });
  };

  let remainingSum = 0;

  return (
    <div className='w-full'>
      <Header text='اضافه تاجر' link='/merchant/form' />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>اسم التاجر</th>
            <th>الاجمالى</th>
            <th>تم الدفع</th>
            <th>المتبقى</th>
            <th className='text-center w-24'>الفواتير</th>
            <th className='text-center w-24'>الدفعات</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {merchants.map((merchant) => {
            remainingSum += merchant.totalPrice - merchant.totalPaid;
            return (
              <tr
                key={merchant.merchantId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{merchant.merchantId}</td>
                <td>
                  <Link
                    to={`/merchant/details/${merchant.merchantId}`}
                    className='hover:text-sky-500'
                  >
                    {merchant.merchantName}
                  </Link>
                </td>
                <td>{format(merchant.totalPrice)}</td>
                <td>{format(merchant.totalPaid)}</td>
                <td>{format(merchant.totalPrice - merchant.totalPaid)}</td>
                <td className='text-center'>
                  <Link to={`/merchant/invoices/${merchant.merchantId}`}>
                    <img
                      src={invoiceIcon}
                      alt='invoiceIcon'
                      className='my-auto mx-auto h-5'
                    />
                  </Link>
                </td>
                <td className='text-center'>
                  <Link
                    to={`/fabric/instalments/${merchant.merchantId}`}
                    className='flex flex-col items-center w-full cursor-pointer'
                  >
                    <img
                      src={instalmentIcon}
                      alt='instalment'
                      className='h-6'
                    />
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/merchant/form/${merchant.merchantId}`}
                      className='flex flex-col items-center w-full cursor-pointer'
                    >
                      <img src={editIcon} alt='edit' className='h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() => deleteMerchant(merchant.merchantId)}
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td colSpan={4} className='text-rose-500'>
              اجمالى المتبقى
            </td>
            <td className='text-rose-500'>{format(remainingSum)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Merchants;
