import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import addIcon from '../../imgs/add.png';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';
import searchIcon from '../../imgs/search.svg';

const Variants = () => {
  const [variants, seVariants] = useState([]);
  const { fabricId = '' } = useParams();
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variants/`);

      if (response.data.statusCode === 200) {
        seVariants(response.data.data);
      }
    })();
  }, []);

  const deleteFabric = async (variantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}variants/delete`, {
          variantId
        });

        if (response.data.statusCode === 200) {
          seVariants(
            variants.filter((variant) => variantId !== variant.variantId)
          );
        }
      }
    });
  };

  let totalQuantity = 0;
  let totalSold = 0;
  let totalRemaining = 0;

  return (
    <div className='w-full'>
      <div className='flex gap-x-5 flex-row mb-5'>
        <div className='font-bold cursor-pointer'>
          <Link
            to={`/variant/form/${fabricId}`}
            className=' bg-green-700 rounded-xl text-white p-3'
          >
            <img src={addIcon} alt='add' className='h-5 inline ml-2' />
            اضافه صنف
          </Link>
        </div>
        <div className='font-bold cursor-pointer'>
          <Link
            to='/variant/search'
            className=' bg-green-700 rounded-xl text-white p-3'
          >
            <img src={searchIcon} alt='add' className='h-5 inline ml-2' />
            بحث بكود الصنف
          </Link>
        </div>
      </div>
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>اسم الخامه</th>
            <th>اسم الصنف</th>
            <th>اجمالى القطع</th>
            <th>اجمالى المبيعات</th>
            <th>اجمالى المتبقى</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {variants.map((variant) => {
            totalQuantity += variant.quantity;
            totalSold += variant.sold;
            totalRemaining += variant.quantity - (variant.sold || 0);

            return (
              <tr
                key={variant.variantId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{variant.variantId}</td>
                <td>
                  <Link
                    to={`/variant/transactions/${variant.variantId}`}
                    className='hover:text-sky-500'
                  >
                    {variant.fabricName}
                  </Link>
                </td>
                <td>{variant.variantName}</td>
                <td>{(variant.quantity || 0).toLocaleString('en-US')}</td>
                <td>{(variant.sold || 0).toLocaleString('en-US')}</td>
                <td>
                  {(
                    (variant.quantity || 0) - (variant.sold || 0)
                  ).toLocaleString('en-US')}
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link to={`/variant/form/${variant.variantId}`}>
                      <img src={editIcon} alt='edit' className='inline h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() => deleteFabric(variant.variantId)}
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td colSpan={3}></td>
            <td>{(totalQuantity || 0).toLocaleString('en-US')}</td>
            <td>{(totalSold || 0).toLocaleString('en-US')}</td>
            <td>{(totalRemaining || 0).toLocaleString('en-US')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Variants;
