import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import settings from '../../config';
import Button from '../Button';
import { useParams } from 'react-router-dom';

const Inventory = () => {
  const [fabrics, setFabrics] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [operations, setOperations] = useState([]);
  const [updated, setUpdated] = useState(false);

  const { inventoryId } = useParams();

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabrics/`);

      if (response.data.statusCode === 200) {
        setFabrics(response.data.data);
      }

      const operationResponse = await axios.post(
        `${settings.api}inventory-operation/`,
        {
          inventoryId
        }
      );

      if (operationResponse.data.statusCode === 200) {
        const operations = operationResponse.data.data;
        setOperations(operations);
      }
    })();
  }, [inventoryId]);

  useEffect(() => {
    if (operations.length && !updated) {
      const updatedFabrics = fabrics.map((fabric) => {
        const operation = operations.find(
          (operation) => operation.itemId === fabric.fabricId
        );

        if (operation) {
          return {
            ...fabric,
            ...operation
          };
        } else {
          return fabric;
        }
      });

      setFabrics(updatedFabrics);
      setUpdated(true);
    }
  }, [fabrics, operations, operations.length, updated]);

  const onChange = (fabricId, value) => {
    setFabrics(
      fabrics.map((fabric) =>
        fabric.fabricId === fabricId
          ? {
              ...fabric,
              actualQuantity: +value
            }
          : fabric
      )
    );
  };

  const onSubmit = useCallback(async () => {
    const payload = {
      inventoryId,
      operations: fabrics.map((fabric) => ({
        itemId: fabric.fabricId,
        actualQuantity: fabric.actualQuantity,
        quantity: fabric.weight
      }))
    };

    const response = await axios.post(
      `${settings.api}inventory-operation/create`,
      payload
    );

    if (response.data.statusCode === 200) {
      setIsSubmitted(true);
    }
  }, [fabrics, inventoryId]);

  return (
    <div className='w-full'>
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>اسم الخامه</th>
            {<th>الوزن المتاح</th>}
            {<th>الوزن داخل المخزن</th>}
          </tr>
        </thead>
        <tbody>
          {fabrics.map((fabric) => {
            return (
              <tr
                key={fabric.fabricId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{fabric.fabricName}</td>

                <td className={(!isSubmitted && 'blur-md') || ''}>
                  {(fabric.weight || 0).toLocaleString('en-US')}
                </td>
                <td>
                  <label className='w-full'>
                    <input
                      onChange={(e) =>
                        onChange(fabric.fabricId, e.target.value)
                      }
                      value={fabric.actualQuantity || 0}
                      className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                    />
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='flex justify-center'>
        <Button className='w-1/2 my-5' text='جرد' action={onSubmit} />
      </div>
    </div>
  );
};

export default Inventory;
