import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";

const AccessoryTransactionForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { accessoryId = "", accessoryTransactionId = "" } = useParams();
  const [accessory, setAccessory] = useState({});
  const [accessoriesMerchants, setMerchants] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}accessories/view`, {
        accessoryId,
      });

      if (response.data.statusCode === 200) {
        setAccessory(response.data.data[0]);
      }
    })();

    (async () => {
      const response = await axios.post(
        `${settings.api}accessories-merchants/`
      );

      if (response.data.statusCode === 200) {
        setMerchants(response.data.data);
      }
    })();
  }, [accessoryId]);

  useEffect(() => {
    if (accessoryTransactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}accessory/view-transaction`,
          {
            accessoryTransactionId,
          }
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [accessoryTransactionId]);

  const [formData, setFormData] = useState({
    accessoryId,
    accessoriesMerchantId: "",
    price: 0,
    weight: "",
    transactionType: 1,
    transactionDate: "",
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = accessoryTransactionId
          ? await axios.post(
              `${settings.api}accessory/update-transaction`,
              formData
            )
          : await axios.post(
              `${settings.api}accessory/create-transaction`,
              formData
            );

        if (response.data.statusCode === 200) {
          if (searchParams.get("from")) {
            navigate(`/${searchParams.get("from")}`);
          } else {
            navigate(`/accessory/transactions/${accessoryId}`);
          }
        }
      }}
    >
      <div className="w-full lg:w-1/2">
        <div className="bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4">
          <div className="flex flex-col text-sm text-gray-500 py-4">
            <div className="flex flex-row justify-center space-x-10 w-full">
              <div className="w-full font-bold text-xl text-center mb-5">
                استماره معامله اكسسوار
              </div>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                اسم الاكسسوار
                <input
                  defaultValue={accessory.accessoryName}
                  disabled
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                اسم تاجر الاكسسوار
                <select
                  value={formData.accessoriesMerchantId}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  name="accessoriesMerchantId"
                >
                  <option value={0}>اختر</option>
                  {accessoriesMerchants.map((accessoriesMerchant) => (
                    <option
                      key={accessoriesMerchant.accessoriesMerchantId}
                      value={accessoriesMerchant.accessoriesMerchantId}
                    >
                      {accessoriesMerchant.accessoriesMerchantName}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                سعر القطعه
                <input
                  placeholder="سعر القطعه"
                  value={formData.price}
                  name="price"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                عدد القطع
                <input
                  placeholder="عدد القطع"
                  value={formData.weight}
                  name="weight"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                السعر الاجمالى
                <input
                  placeholder="السعر الاجمالى"
                  value={formData.price * formData.weight || 0}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  disabled
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                تاريخ المعامله
                <input
                  placeholder="yyyy-mm-dd"
                  value={formData.transactionDate}
                  name="transactionDate"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
              </label>
            </div>
            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              <div className="w-1/2">
                <Button
                  type="submit"
                  text="تسجيل"
                  isDisabled={
                    !(
                      formData.accessoryId &&
                      formData.weight &&
                      formData.transactionType &&
                      formData.transactionDate
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccessoryTransactionForm;
