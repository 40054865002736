import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';
import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';
import instalment from '../../imgs/instalment.png';

const AccessoriesMerchants = () => {
  const [accessoriessMerchants, setAccessoriesMerchants] = useState([]);
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}accessories-merchants/`
      );

      if (response.data.statusCode === 200) {
        setAccessoriesMerchants(response.data.data);
      }
    })();
  }, []);

  const deleteAccessoriesMerchant = async (accessoriesMerchantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}accessories-merchants/delete`,
          {
            accessoriesMerchantId
          }
        );

        if (response.data.statusCode === 200) {
          setAccessoriesMerchants(
            accessoriessMerchants.filter(
              (merchant) =>
                accessoriesMerchantId !== merchant.accessoriesMerchantId
            )
          );
        }
      }
    });
  };

  let remainingSum = 0;

  return (
    <div className='w-full'>
      <Header text='اضافه تاجر اكسسوارات' link='/accessories-merchant/form' />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>اسم تاجر الاكسسوار</th>
            <th>الاجمالى</th>
            <th>تم الدفع</th>
            <th>المتبقى</th>
            <th className='text-center w-24'>الدفعات</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {accessoriessMerchants.map((merchant) => {
            remainingSum += merchant.totalPrice - merchant.totalPaid;
            return (
              <tr
                key={merchant.accessoriesMerchantId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{merchant.accessoriesMerchantId}</td>
                <td>
                  <Link
                    to={`/accessories-merchant/details/${merchant.accessoriesMerchantId}`}
                    className='hover:text-sky-500'
                  >
                    {merchant.accessoriesMerchantName}
                  </Link>
                </td>
                <td>{format(merchant.totalPrice || 0)}</td>
                <td>{format(merchant.totalPaid || 0)}</td>
                <td>{format(merchant.totalPrice - merchant.totalPaid || 0)}</td>
                <td className='text-center'>
                  <div className='flex flex-col items-center w-full cursor-pointer'>
                    <div className='font-bold rounded-full w-8'>
                      <Link
                        to={`/accessory/instalments/${merchant.accessoriesMerchantId}`}
                      >
                        <img
                          src={instalment}
                          alt='instalment'
                          className='h-6'
                        />
                      </Link>
                    </div>
                  </div>
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/accessories-merchant/form/${merchant.accessoriesMerchantId}`}
                    >
                      <img src={editIcon} alt='edit' className='inline h-10' />
                    </Link>
                  </td>
                )}

                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteAccessoriesMerchant(
                          merchant.accessoriesMerchantId
                        )
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td colSpan={4} className='text-rose-500'>
              اجمالى المتبقى
            </td>
            <td className='text-rose-500'>{format(remainingSum || 0)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AccessoriesMerchants;
