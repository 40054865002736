const Button = ({ text, isDisabled = false, small, action, type, className }) => {
  let bgColor = isDisabled
    ? "bg-purple-800/30 text-white"
    : "text-white hover:bg-purple-800 bg-purple-800/80 cursor-pointer";

  if (small) {
    bgColor = "bg-gray-200 text-black";
  }

  return (
    <button
      className={`w-full font-bold ${bgColor} px-4 py-1 rounded-lg drop-shadow ${className}`}
      type={type}
      disabled={isDisabled}
      onClick={action}
    >
      {text}
    </button>
  );
};

export default Button;
