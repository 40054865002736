import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import { format } from '../../utils';
import inOut from '../../imgs/inout.png';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';
import tShirtIcon from '../../imgs/t-shirt.svg';

const Accessories = () => {
  const [accessories, seAccessories] = useState([]);
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}accessories/`);

      if (response.data.statusCode === 200) {
        seAccessories(response.data.data);
      }
    })();
  }, []);

  const deleteAccesory = async (accessoryId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}accessories/delete`, {
          accessoryId
        });

        if (response.data.statusCode === 200) {
          seAccessories(
            accessories.filter(
              (accessory) => accessoryId !== accessory.accessoryId
            )
          );
        }
      }
    });
  };

  return (
    <div className='w-full'>
      <Header text='اضافه اكسسوار' link='/accessory/form' />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>اسم الاكسسوار</th>
            <th>السعر الاجمالى</th>
            <th>العددي الاجمالى</th>
            <th>العدد المتاح</th>
            <th className='text-center w-24'>تركيب</th>
            <th className='text-center w-24'>معاملات</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {accessories.map((accessory) => {
            return (
              <tr
                key={accessory.accessoryId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{accessory.accessoryName}</td>
                <td>{format(accessory.price || 0)}</td>
                <td>{accessory.weight || 0}</td>
                <td>{accessory.weight - accessory.usedWeight || 0}</td>
                <td className='text-center'>
                  <div className='flex flex-col items-center w-full cursor-pointer'>
                    <div className='font-bold rounded-full'>
                      <Link to={`/accessory/variants/${accessory.accessoryId}`}>
                        <img
                          src={tShirtIcon}
                          alt='tShirtIcon'
                          className='h-8'
                        />
                      </Link>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <Link
                    className='cursor-pointer'
                    to={`/accessory/transactions/${accessory.accessoryId}`}
                  >
                    <img src={inOut} alt='in-out' className='inline h-8' />
                  </Link>
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link to={`/accessory/form/${accessory.accessoryId}`}>
                      <img src={editIcon} alt='edit' className='inline h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() => deleteAccesory(accessory.accessoryId)}
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Accessories;
