import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';

import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';

const ClientDetails = () => {
  const [clientDetails, setClientDetails] = useState([]);
  const { clientId = '' } = useParams();
  const [admin] = useLocalStorage('admin');

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}clients/details`, {
        clientId
      });
      if (response.data.statusCode === 200) {
        setClientDetails(response.data.data);
      }
    })();
  }, [clientId]);

  let totalWithDiscount = 0;
  let finalPrice = 0;
  let totalPrice = 0;

  const boxes = clientDetails.reduce((acc, clientDetail) => {
    return {
      ...acc,
      [clientDetail.category]:
        (acc[clientDetail.category] || 0) + clientDetail.quantity
    };
  }, {});

  return (
    <div className='w-full'>
      <div className='flex gap-x-8 gap-y-8 flex-wrap mb-4'>
        {Object.keys(boxes).map((key) => {
          return (
            <div
              className={`border ${
                key === 'null' ? 'bg-rose-100' : 'bg-green-100'
              } p-10 rounded-lg`}
              key={key}
            >
              <div className='text-center'>{key}</div>
              <div className='text-xl text-center'>{boxes[key]}</div>
            </div>
          );
        })}
      </div>
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>كود الصنف</th>
            <th>اسم الصنف</th>
            <th>الكميه</th>
            <th>الاجمالى</th>
            <th>الخصم</th>
            <th>الاجمالى بالخصم</th>
            <th>تاريخ البيع</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
          </tr>
        </thead>
        <tbody>
          {clientDetails.map((clientDetail) => {
            finalPrice = clientDetail.clientPrice * clientDetail.quantity;

            totalWithDiscount = finalPrice;

            if (clientDetail.discountType === 2) {
              totalWithDiscount =
                finalPrice - clientDetail.quantity * clientDetail.discount;
            }

            if (clientDetail.discountType === 1) {
              totalWithDiscount =
                finalPrice - (finalPrice * clientDetail.discount) / 100;
            }

            totalPrice += totalWithDiscount;

            return (
              <tr
                key={clientDetail.variantClientId}
                className={` hover:bg-orange-100 odd:bg-gray-100 bg-white `}
              >
                <td className='w-24 text-center'>{clientDetail.variantCode}</td>
                <td>
                  <Link
                    to={`/fabric/variant/form/${clientDetail.fabricTransactionId}/${clientDetail.fabricTransactionVariantId}`}
                  >
                    {clientDetail.variantType}
                  </Link>
                </td>
                <td>{clientDetail.quantity}</td>
                <td>{format(finalPrice || 0)}</td>
                <td>
                  {`${(clientDetail.discountType === 1 && '%') || ''}${
                    clientDetail.discount
                  }`}
                </td>
                <td>{format(totalWithDiscount || 0)}</td>
                <td>{clientDetail.processingDate}</td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/variant/sell/form/${clientDetail.fabricTransactionVariantId}/${clientDetail.variantClientId}?from=client/details/${clientId}`}
                    >
                      <img src={editIcon} alt='edit' className='inline h-10' />
                    </Link>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td>الاجمالى</td>
            <td colSpan={5}></td>
            <td>{format(totalPrice || 0)}</td>
            <td colSpan={2}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ClientDetails;
