import { useEffect, useState } from 'react';
import axios from 'axios';

import { format } from '../../utils';
import settings from '../../config';

const types = {
  variant_client_instalments: 'مبيعات عملاء',
  fabric_instalments: 'مدفوعات الخامات',
  accessory_instalments: 'مدفوعات اكسسوارات',
  bills: 'دفتر اليوميه'
};

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState();

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}transactions/`);

      if (response.data.statusCode === 200) {
        setTransactions(response.data.data);
      }
    })();
  }, []);

  const cash = transactions.reduce((acc, value) => {
    if (value.paymentMethod === 'نقدى') {
      if (value.type === 'variant_client_instalments') {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const vodafoneCash = transactions.reduce((acc, value) => {
    if (value.paymentMethod === 'فودافون كاش') {
      if (value.type === 'variant_client_instalments') {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const etisalatCash = transactions.reduce((acc, value) => {
    if (value.paymentMethod === 'اتصالات كاش') {
      if (value.type === 'variant_client_instalments') {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const post = transactions.reduce((acc, value) => {
    if (value.paymentMethod === 'بوسطه') {
      if (value.type === 'variant_client_instalments') {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const bank = transactions.reduce((acc, value) => {
    if (value.paymentMethod === 'بنك') {
      if (value.type === 'variant_client_instalments') {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  const cheque = transactions.reduce((acc, value) => {
    if (value.paymentMethod === 'شيك') {
      if (value.type === 'variant_client_instalments') {
        return value.amount + acc;
      } else {
        return acc - value.amount;
      }
    } else {
      return acc;
    }
  }, 0);

  return (
    <div className='w-full'>
      <div className='flex gap-x-8 gap-y-8 flex-wrap'>
        <div
          className='border p-10 rounded-lg cursor-pointer'
          onClick={() => {
            setFilter('نقدى');
          }}
        >
          <div className='text-center'>نقدى</div>
          <div className='text-xl text-center'>{format(cash)}</div>
        </div>
        <div
          className='border p-10 rounded-lg  cursor-pointer'
          onClick={() => {
            setFilter('فودافون كاش');
          }}
        >
          <div className='text-center'>فودافون كاش</div>
          <div className='text-xl text-center'>{format(vodafoneCash)}</div>
        </div>
        <div
          className='border p-10 rounded-lg  cursor-pointer'
          onClick={() => {
            setFilter('اتصالات كاش');
          }}
        >
          <div className='text-center'>اتصالات كاش</div>
          <div className='text-xl text-center'>{format(etisalatCash)}</div>
        </div>
        <div
          className='border p-10 rounded-lg  cursor-pointer'
          onClick={() => {
            setFilter('بوسطه');
          }}
        >
          <div className='text-center'>بوسطه</div>
          <div className='text-xl text-center'>{format(post)}</div>
        </div>
        <div
          className='border p-10 rounded-lg  cursor-pointer'
          onClick={() => {
            setFilter('بنك');
          }}
        >
          <div className='text-center'>بنك</div>
          <div className='text-xl text-center'>{format(bank)}</div>
        </div>
        <div
          className='border p-10 rounded-lg  cursor-pointer'
          onClick={() => {
            setFilter('شيك');
          }}
        >
          <div className='text-center'>شيك</div>
          <div className='text-xl text-center'>{format(cheque)}</div>
        </div>
      </div>
      {filter && (
        <table
          key={filter}
          className='w-full table-auto border-separate border-spacing-1 text-right mt-5'
        >
          <thead>
            <tr>
              <th>النوع</th>
              <th>الاسم</th>
              <th>القيمه</th>
              <th>تاريخ الدفعه</th>
              <th>طريقه الدفع</th>
            </tr>
          </thead>
          <tbody>
            {transactions
              .filter((item) => item.paymentMethod === filter)
              .map((transaction) => {
                return (
                  <tr
                    key={transaction.paymentMethod + transaction.type}
                    className={`${
                      transaction.type === 'variant_client_instalments'
                        ? 'bg-green-100'
                        : 'bg-rose-100'
                    }`}
                  >
                    <td>{types[transaction.type]}</td>
                    <td>{transaction.name}</td>
                    <td>{format(transaction.amount)}</td>
                    <td>{transaction.instalmentDate}</td>
                    <td>{transaction.paymentMethod}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Transactions;
