import { useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { ModalContext } from '../../context';
import useLocalStorage from '../../hooks/useLocalStorage';
import Router from '../Router';

import assetsIcon from '../../imgs/assets.png';
import billIcon from '../../imgs/bill.png';
import merchantIcon from '../../imgs/merchant.webp';
import fabricIcon from '../../imgs/fabric.png';
import accessoriesIcon from '../../imgs/accessories.png';
import workerIcon from '../../imgs/worker.png';
import tshirtIcon from '../../imgs/t-shirt.svg';
import clientIcon from '../../imgs/client.png';
import moneyBagIcon from '../../imgs/money-bag.svg';
import homePageIcon from '../../imgs/homepage.svg';
import InventoryIcon from '../../imgs/inventory.png';
import logoutIcon from '../../imgs/logout.png';
import Button from '../Button';

const Item = ({ path, Icon, text, action }) => (
  <Link to={path}>
    <Button
      action={action}
      className='p-4 bg-gray-100 hover:bg-gray-300 text-black items-center flex'
      text={
        <>
          <img alt='' src={Icon} className='h-8 inline-block ml-2' />
          {text}
        </>
      }
    />
  </Link>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useLocalStorage('admin');
  const [modal, setModal] = useContext(ModalContext);

  useEffect(() => {
    if (!admin?.adminId || admin?.canEdit === undefined) {
      setAdmin({});
      navigate('/');
    }
  }, [admin?.adminId, navigate, setAdmin, admin?.canEdit]);

  return (
    <div className='w-full flex md:flex-row flex-col p-4'>
      <div className='w-full md:w-[250px] justify-center md:justify-start flex md:flex-col flex-wrap flex-row font-bold gap-2 pb-4 md:pb-0 print:hidden'>
        <Item path='/' Icon={homePageIcon} text='الرئيسيه' />
        <Item path='/transactions' Icon={moneyBagIcon} text='الحسابات' />
        <Item path='/assets' Icon={assetsIcon} text='الاصول' />
        <Item path='/bill-categories' Icon={billIcon} text='المدفوعات' />
        <Item path='/merchants' Icon={merchantIcon} text='تجار الخام' />
        <Item path='/fabrics' Icon={fabricIcon} text='الحام' />
        <Item path='/fabric-inventory' Icon={InventoryIcon} text='جرد الحام' />
        <Item
          path='/production-assets'
          Icon={workerIcon}
          text='متطلبات الانتاج'
        />
        <Item
          path='/accessories-merchants'
          Icon={merchantIcon}
          text='تجار الاكسسوارات'
        />
        <Item path='/accessories' Icon={accessoriesIcon} text='الاكسسوارات' />
        <Item
          path='/accessories-inventory'
          Icon={InventoryIcon}
          text='جرد الاكسسوارات'
        />
        <Item path='/variants' Icon={tshirtIcon} text='الاصناف' />
        <Item
          path='/variants-inventory'
          Icon={InventoryIcon}
          text='جرد الاصناف'
        />
        <Item path='/clients' Icon={clientIcon} text='العملاء' />
        <Item
          path='#'
          Icon={logoutIcon}
          text='تسجيل خروج'
          action={() => {
            setAdmin({});
          }}
        />
        {/* {
        <Link to="/employees" className="p-4 bg-gray-100 hover:bg-gray-300">
          الموظفين
        </Link>} */}
      </div>
      <div className='w-full px-4 bg-cover'>
        <Router />
      </div>
      {(modal.isModalOpen && (
        <div className='absolute w-full h-full bg-black/80 top-0 left-0 text-rose-500 align-middle flex justify-center items-center font-bold'>
          <div
            className='w-1/4 h-40 bg-slate-100 rounded-xl flex justify-center items-center flex-col gap-5'
            dir='ltr'
          >
            Are you sure you want to delete?
            <div className='flex gap-5' dir='rtl'>
              <Button
                small
                text='Cancel'
                action={() => {
                  setModal({
                    isModalOpen: false,
                    modalAction: () => {}
                  });
                }}
              />
              <Button
                text='Confirm'
                action={() => {
                  modal.modalAction();
                  setModal({
                    isModalOpen: false,
                    modalAction: () => {}
                  });
                }}
              />
            </div>
          </div>
        </div>
      )) ||
        null}
    </div>
  );
};

export default Dashboard;
