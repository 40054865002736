import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";

const VariantForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { fabricTransactionVariantId = "", variantClientId = "" } = useParams();
  const [clients, setClients] = useState([]);

  const [formData, setFormData] = useState({
    clientId: "",
    variantClientId,
    fabricTransactionVariantId,
    quantity: 0,
    clientPrice: 0,
    processingDate: "",
    discount: 0,
    discountType: 0,
  });

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}clients/`);
      setClients(response.data.data);
    })();
  }, []);

  useEffect(() => {
    if (variantClientId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}variant-client/view`,
          {
            variantClientId,
          }
        );

        if (response.data.data) {
          setFormData((oldState) => ({
            ...oldState,
            ...response.data.data[0],
          }));
        }
      })();
    }
  }, [variantClientId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}variant-client/${
            variantClientId ? "update" : "create"
          }`,
          formData
        );

        if (response.data.statusCode === 200) {
          if (searchParams.get("from")) {
            navigate(`/${searchParams.get("from")}`);
          } else {
            navigate(`/variant/sell/${fabricTransactionVariantId}`);
          }
        }
      }}
    >
      <div className="w-full lg:w-1/2">
        <div className="bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4">
          <div className="flex flex-col text-sm text-gray-500 py-4">
            <div className="flex flex-row justify-center space-x-10 w-full">
              <div className="w-full font-bold text-xl text-center mb-5">
                استماره مبيعات
              </div>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                اسم العميل
                <select
                  value={formData.clientId}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  name="clientId"
                >
                  <option value="">اختر</option>
                  {clients.map((client) => (
                    <option key={client.clientId} value={client.clientId}>
                      {client.clientName} - {client.clientCode}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                الكميه
                <input
                  placeholder="الكميه"
                  value={formData.quantity}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  name="quantity"
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                سعر البيع
                <input
                  placeholder="سعر البيع"
                  value={formData.clientPrice}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  name="clientPrice"
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                نوع الخصم
                <select
                  value={formData.discountType}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  name="discountType"
                >
                  <option value="">اختر</option>
                  <option key="percentage" value={1}>
                    نسبه مئويه
                  </option>
                  <option key="amount" value={2}>
                    خصم بالجنيه
                  </option>
                </select>
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                الخصم
                <input
                  placeholder="الخصم"
                  value={formData.discount}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  name="discount"
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                التاريخ
                <input
                  placeholder="التاريخ"
                  value={formData.processingDate}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  name="processingDate"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
              </label>
            </div>

            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              <div className="w-1/2">
                <Button
                  type="submit"
                  text="تسجيل"
                  isDisabled={
                    !(
                      formData.fabricTransactionVariantId &&
                      formData.clientId &&
                      formData.processingDate &&
                      formData.clientPrice &&
                      formData.quantity
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default VariantForm;
