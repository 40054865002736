import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { ModalContext } from '../../context';
import useLocalStorage from '../../hooks/useLocalStorage';
import settings from '../../config';
import todoIcon from '../../imgs/todo.png';
import deleteIcon from '../../imgs/delete.png';
import Button from '../Button';

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}inventory/`, {
        type: 'fabric'
      });

      if (response.data.statusCode === 200) {
        setInventory(response.data.data);
      }
    })();
  }, []);

  const deleteInventory = async (inventoryId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}inventory/delete`, {
          inventoryId
        });

        if (response.data.statusCode === 200) {
          if (response.data.data) {
            setInventory(
              inventory.filter((record) => record.inventoryId !== inventoryId)
            );
          }
        }
      }
    });
  };

  const handleCreate = async () => {
    const response = await axios.post(`${settings.api}inventory/create`, {
      type: 'fabric'
    });

    if (response.data.statusCode === 200) {
      setInventory([...inventory, response.data.data[0]]);
    }
  };

  return (
    <div className='w-full'>
      <div className='flex justify-center'>
        <Button text='جرد جديد' action={handleCreate} className='w-1/4' />
      </div>
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>تاريخ الجرد</th>
            <th className='text-center w-24'>التفاصيل</th>
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {inventory.map((record) => {
            return (
              <tr
                key={record.inventoryId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{record.inventoryDate}</td>
                <td className='text-center'>
                  <Link to={`/fabric-inventory/${record.inventoryId}`}>
                    <img
                      src={todoIcon}
                      alt='todoIcon'
                      className='my-auto mx-auto h-5'
                    />
                  </Link>
                </td>
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() => deleteInventory(record.inventoryId)}
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Inventory;
