import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';

import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';

const MerchantDetails = () => {
  const [merchantDetails, setMerchantDetails] = useState([]);
  const { merchantId = '' } = useParams();
  const [admin] = useLocalStorage('admin');

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}merchants/details`, {
        merchantId
      });
      if (response.data.statusCode === 200) {
        setMerchantDetails(response.data.data);
      }
    })();
  }, [merchantId]);

  return (
    <div className='w-full'>
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>اسم الخامه</th>
            <th>الوزن</th>
            <th>السعر</th>
            <th>الاجمالى</th>
            <th>تاريخ الشراء</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
          </tr>
        </thead>
        <tbody>
          {merchantDetails.map((merchantDetail) => {
            return (
              <tr
                key={merchantDetail.fabricTransactionId}
                className={`bg-white hover:bg-orange-100 odd:bg-gray-100`}
              >
                <td>{merchantDetail.fabricTransactionId}</td>
                <td className='text-center'>{merchantDetail.fabricName}</td>
                <td>{(merchantDetail.weight || 0).toLocaleString('en-US')}</td>
                <td>{format(merchantDetail.price)}</td>
                <td>{format(merchantDetail.totalPrice)}</td>
                <td>{merchantDetail.transactionDate}</td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <div className='flex flex-col items-center w-full cursor-pointer'>
                      <div className='font-bold rounded-full w-8'>
                        <Link
                          to={`/fabric/transactions/form/${merchantDetail.fabricId}/${merchantDetail.fabricTransactionId}?from=merchant/details/${merchantId}`}
                        >
                          <img src={editIcon} alt='delivery' />
                        </Link>
                      </div>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MerchantDetails;
