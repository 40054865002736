import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';

const FabricVariantTransactions = () => {
  const [accessoryVariants, setFabricInstalments] = useState([]);
  const { accessoryId } = useParams();
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${settings.api}accessory/variant-transactions`,
        {
          accessoryId
        }
      );

      if (response.data.statusCode === 200) {
        setFabricInstalments(response.data.data);
      }
    })();
  }, [accessoryId]);

  const deleteProductInstalment = async (accessoryVariantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}accessory/delete-variant-transaction`,
          {
            accessoryVariantId
          }
        );

        if (response.data.statusCode === 200) {
          setFabricInstalments(
            accessoryVariants.filter(
              (accessoryVariant) =>
                accessoryVariantId !==
                accessoryVariant.accessoryTransactionVariantId
            )
          );
        }
      }
    });
  };

  return (
    <div className='w-full'>
      <Header
        text='اضافه منتجات'
        link={`/accessory/variant/form/${accessoryId}`}
      />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>كود المنتج</th>
            <th>اسم المنتج</th>
            <th>العدد</th>
            <th>السعر بالقطعه</th>
            <th>السعر الاجمالى</th>
            <th>تاريخ الدفعه</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {accessoryVariants.map((accessoryVariant) => {
            return (
              <tr
                key={accessoryVariant.accessoryTransactionVariantId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{accessoryVariant.variantCode}</td>
                <td>{accessoryVariant.variantName}</td>
                <td>{accessoryVariant.quantity}</td>
                <td>{format(accessoryVariant.price || 0)}</td>
                <td>
                  {format(
                    accessoryVariant.price * accessoryVariant.quantity || 0
                  )}
                </td>
                <td>{accessoryVariant.processingDate}</td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/accessory/variant/form/${accessoryId}/${accessoryVariant.accessoryTransactionVariantId}`}
                      className='flex flex-col items-center w-full cursor-pointer'
                    >
                      <img src={editIcon} alt='edit' className='h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteProductInstalment(
                          accessoryVariant.accessoryTransactionVariantId
                        )
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricVariantTransactions;
