import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Button from "../Button";

import settings from "../../config";

const EmployeeForm = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    joinDate: "",
    hourlyRate: "",
    salaryFrequency: "",
    jobTitle: "",
    shift: "",
    status: 0,
    deleted: 0,
  });

  useEffect(() => {
    if (employeeId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}employees/view`,
          {
            employeeId: employeeId,
          }
        );

        if (response.data.data) {
          setFormData(response.data.data[0] || {});
        }
      })();
    }
  }, [employeeId]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}employees/${employeeId ? "update" : "create"}`,
          formData
        );

        if (response.data.statusCode === 200) {
          navigate("/employees");
        }
      }}
    >
      <div className="w-full lg:w-1/2">
        <div className="bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4">
          <div className="flex flex-col text-sm text-gray-500 py-4">
            <div className="flex flex-row justify-center space-x-10 w-full">
              <div className="w-full font-bold text-xl text-center mb-5">
                استماره الموظف
              </div>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                الاسم
                <input
                  placeholder="الاسم"
                  value={formData.name}
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  name="name"
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                تاريخ التعيين
                <input
                  placeholder="yyyy-mm-dd"
                  value={formData.joinDate}
                  name="joinDate"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                سعر الساعه
                <input
                  placeholder="150"
                  value={formData.hourlyRate}
                  name="hourlyRate"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                فتره المرتب
                <input
                  placeholder="daily, weekly, monthly"
                  value={formData.salaryFrequency}
                  name="salaryFrequency"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                الوظيفه
                <input
                  placeholder="محاسب"
                  value={formData.jobTitle}
                  name="jobTitle"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex space-x-10 w-full">
              <label className="w-full">
                عدد ساعات العمل
                <input
                  placeholder="9"
                  value={formData.shift}
                  name="shift"
                  onChange={onChange}
                  className="w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  required
                />
              </label>
            </div>
            <div className="flex flex-row justify-center space-x-10 w-full mt-5">
              <div className="w-1/2">
                <Button
                  type="submit"
                  text="تسجيل"
                  isDisabled={
                    !(
                      formData.name &&
                      formData.joinDate &&
                      formData.hourlyRate &&
                      formData.salaryFrequency &&
                      formData.jobTitle &&
                      formData.shift
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EmployeeForm;
