import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Button from '../Button';

import settings from '../../config';

const FabricCutForm = () => {
  const navigate = useNavigate();
  const { fabricId = '', transactionId = null } = useParams();
  const [fabric, setFabric] = useState({});

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}fabrics/view`, {
        fabricId
      });

      if (response.data.statusCode === 200) {
        setFabric(response.data.data[0]);
      }
    })();
  }, [fabricId]);

  useEffect(() => {
    if (transactionId) {
      (async () => {
        const response = await axios.post(
          `${settings.api}fabric/view-transaction`,
          {
            transactionId
          }
        );

        setFormData(response.data.data[0]);
      })();
    }
  }, [transactionId]);

  const [formData, setFormData] = useState({
    fabricId,
    weight: '',
    transactionType: -1,
    transactionDate: ''
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response =
          (transactionId &&
            (await axios.post(
              `${settings.api}fabric/update-transaction`,
              formData
            ))) ||
          (await axios.post(
            `${settings.api}fabric/create-transaction`,
            formData
          ));

        if (response.data.statusCode === 200) {
          navigate(`/fabric/cuts/${fabricId}`);
        }
      }}
    >
      <div className='w-full lg:w-1/2'>
        <div className='bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4'>
          <div className='flex flex-col text-sm text-gray-500 py-4'>
            <div className='flex flex-row justify-center space-x-10 w-full'>
              <div className='w-full font-bold text-xl text-center mb-5'>
                استماره قص الخامات
              </div>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                اسم الخامه
                <input
                  defaultValue={fabric.fabricName}
                  disabled
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                />
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                الوزن
                <input
                  placeholder='الوزن'
                  value={formData.weight}
                  name='weight'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                />
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                تاريخ المعامله
                <input
                  placeholder='yyyy-mm-dd'
                  value={formData.transactionDate}
                  name='transactionDate'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}'
                />
              </label>
            </div>
            <div className='flex flex-row justify-center space-x-10 w-full mt-5'>
              <div className='w-1/2'>
                <Button
                  type='submit'
                  text='تسجيل'
                  isDisabled={
                    !(
                      formData.fabricId &&
                      formData.weight &&
                      formData.transactionType &&
                      formData.transactionDate
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FabricCutForm;
