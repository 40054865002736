import { useCallback, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import Button from '../Button';
import settings from '../../config';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';

const VariantOperationsDetails = () => {
  const [variantDetails, setVariantDetails] = useState([]);
  const [admin] = useLocalStorage('admin');

  const [, setModal] = useContext(ModalContext);

  const { fabricTransactionVariantId } = useParams();
  const [accessories, setAccessories] = useState([]);
  const [productionAssets, setProductionAssets] = useState([]);

  const initAccessoriesFormData = {
    accessoryTransactionVariantId: '',
    accessoryId: '',
    fabricTransactionVariantId,
    quantity: '',
    price: '',
    processingDate: ''
  };

  const initProductionAssetsFormData = {
    productionAssetTransactionVariantId: '',
    productionAssetId: '',
    fabricTransactionVariantId,
    price: '',
    processingDate: ''
  };

  const [accessoriesFormData, setAccessoriesFormData] = useState(
    initAccessoriesFormData
  );

  const [productionAssetsFormData, setProductionAssetsFormData] = useState(
    initProductionAssetsFormData
  );

  const onAccessoriesChange = (e) => {
    setAccessoriesFormData({
      ...accessoriesFormData,
      [e.target.name]: e.target.value
    });
  };

  const onProductionAssetsChange = (e) => {
    setProductionAssetsFormData({
      ...productionAssetsFormData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}accessories/`);

      setAccessories(response.data.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}production-assets/`);

      setProductionAssets(response.data.data);
    })();
  }, []);

  const onEditAccessory = (accessory) => {
    setAccessoriesFormData({
      accessoryTransactionVariantId: accessory.accessoryTransactionVariantId,
      accessoryId: accessory.fabricId,
      fabricTransactionVariantId: accessory.fabricTransactionVariantId,
      quantity: accessory.quantity,
      price: accessory.price,
      processingDate: accessory.processingDate
    });
  };

  const onEditProductionAsset = (productionAsset) => {
    setProductionAssetsFormData({
      productionAssetTransactionVariantId:
        productionAsset.accessoryTransactionVariantId,
      productionAssetId: productionAsset.fabricId,
      fabricTransactionVariantId: productionAsset.fabricTransactionVariantId,
      price: productionAsset.price,
      processingDate: productionAsset.processingDate
    });
  };

  const getVariantTransactionDetails = useCallback(async () => {
    const response = await axios.post(
      `${settings.api}fabric/variant-transaction-details`,
      {
        fabricTransactionVariantId
      }
    );
    setVariantDetails(response.data.data);
  }, [fabricTransactionVariantId]);

  useEffect(() => {
    getVariantTransactionDetails();
  }, [getVariantTransactionDetails]);

  const fabric =
    (variantDetails || []).find(
      (variantDetail) => variantDetail.type === 'fabric'
    ) || {};
  const filteredAccessories =
    (variantDetails || []).filter(
      (variantDetail) => variantDetail.type === 'accessory'
    ) || [];
  const filteredProductionAssets =
    (variantDetails || []).filter(
      (variantDetail) => variantDetail.type === 'production_asset'
    ) || [];

  const accessoriesTotalPrice = filteredAccessories.reduce((acc, accessory) => {
    return accessory.price * accessory.quantity + acc;
  }, 0);

  const productionAssetsTotalPrice = filteredProductionAssets.reduce(
    (acc, productionAsset) => {
      return productionAsset.price + acc;
    },
    0
  );

  const deleteAccessoryVariant = async (accessoryVariantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}accessory/delete-variant-transaction`,
          {
            accessoryVariantId
          }
        );

        if (response.data.statusCode === 200) {
          setVariantDetails(
            variantDetails.filter(
              (accessoryVariant) =>
                accessoryVariantId !==
                accessoryVariant.accessoryTransactionVariantId
            )
          );
        }
      }
    });
  };

  const deleteProductInstalment = async (productionAssetVariantId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}production-assets/delete-variant-transaction`,
          {
            productionAssetVariantId
          }
        );

        if (response.data.statusCode === 200) {
          setVariantDetails(
            variantDetails.filter(
              (productionAssetVariant) =>
                productionAssetVariantId !==
                productionAssetVariant.accessoryTransactionVariantId
            )
          );
        }
      }
    });
  };

  return (
    <div className='w-full items-center justify-center'>
      <div className='bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4'>
        <div className='flex flex-col text-sm text-gray-500 py-4'>
          <div className='flex flex-row justify-center space-x-10 w-full'>
            <div className='w-full font-bold text-xl text-center mb-5'>
              {fabric.variantName}
            </div>
          </div>
          <div className='w-full'>
            <div className='text-right w-full m-5'>
              اجمالى عدد القطع: {fabric.quantity}
            </div>
            <div className='text-right w-full m-5 text-green-500'>
              سعر القطعه الواحده:{' '}
              {fabric.price +
                accessoriesTotalPrice +
                productionAssetsTotalPrice}
            </div>
            <div className='text-right w-full m-5 text-red-500'>
              اجمالى الطلبيه:{' '}
              {(fabric.price +
                accessoriesTotalPrice +
                productionAssetsTotalPrice) *
                fabric.quantity}
            </div>
            <div className='text-center w-full m-5'>الخامات</div>
            <table className='table-auto w-full text-center'>
              <thead>
                <tr>
                  <th>الخامه</th>
                  <th>عدد الوحدات</th>
                  <th className='text-rose-500'>سعر الخامه للقطعه</th>
                </tr>
              </thead>
              <tbody>
                <tr className='bg-slate-50'>
                  <td>{fabric.fabricName}</td>
                  <td>{fabric.quantity}</td>
                  <td className='text-rose-500'>{fabric.price || 0}</td>
                </tr>
              </tbody>
            </table>
            <div className='text-center w-full m-5'>الاكسسوارات</div>
            <table className='table-auto w-full text-center'>
              <thead>
                <tr>
                  <th>الاكسسوار</th>
                  <th>عدد الوحدات</th>
                  <th>السعر للقطعه</th>
                  {admin.canEdit === 1 && (
                    <th className='text-center w-24'>تعديل</th>
                  )}
                  {admin.canDelete === 1 && (
                    <th className='text-center w-24'>حذف</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredAccessories.map((accessory) => (
                  <tr
                    className='bg-slate-50'
                    key={`accessory-${accessory.accessoryName}`}
                  >
                    <td>{accessory.accessoryName}</td>
                    <td>{accessory.quantity}</td>
                    <td>{accessory.price}</td>
                    {admin.canEdit === 1 && (
                      <td className='text-center'>
                        <div
                          className='flex flex-col items-center w-full cursor-pointer'
                          onClick={() => onEditAccessory(accessory)}
                        >
                          <img src={editIcon} alt='edit' className='h-10' />
                        </div>
                      </td>
                    )}
                    {admin.canDelete === 1 && (
                      <td className='text-center'>
                        <div
                          className='flex flex-col items-center w-full cursor-pointer'
                          onClick={() =>
                            deleteAccessoryVariant(
                              accessory.accessoryTransactionVariantId
                            )
                          }
                        >
                          <img src={deleteIcon} alt='delete' className='h-5' />
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
                <tr className='text-rose-500'>
                  <td>الاجمالى</td>
                  <td></td>
                  <td>{accessoriesTotalPrice}</td>
                </tr>
              </tbody>
            </table>
            <form
              onSubmit={async (e) => {
                e.preventDefault();

                const response = await axios.post(
                  `${settings.api}accessory/${
                    accessoriesFormData.accessoryTransactionVariantId !== ''
                      ? 'update'
                      : 'create'
                  }-variant-transaction`,
                  accessoriesFormData
                );

                if (response.data.data) {
                  getVariantTransactionDetails();
                  setAccessoriesFormData(initAccessoriesFormData);
                }
              }}
            >
              <div className='flex flex-row text-sm text-gray-500 py-4 w-full gap-4'>
                <input
                  type='hidden'
                  name='fabricTransactionVariantId'
                  value={fabricTransactionVariantId}
                />
                <div className='flex flex-row space-x-10 w-full'>
                  <label className='w-full'>
                    الاكسسوار
                    <select
                      value={accessoriesFormData.accessoryId}
                      name='accessoryId'
                      onChange={onAccessoriesChange}
                      className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                      required
                      pattern='[0-9]{4}-[0-9]2}-[0-9]{2}'
                    >
                      <option value=''>اختر</option>
                      {accessories.map((accessory) => (
                        <option
                          key={accessory.accessoryId}
                          value={accessory.accessoryId}
                        >
                          {`${accessory.accessoryName}`}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className='flex space-x-10 w-full'>
                  <label className='w-full'>
                    العدد
                    <input
                      placeholder='العدد'
                      value={accessoriesFormData.quantity}
                      name='quantity'
                      onChange={onAccessoriesChange}
                      className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                      required
                    />
                  </label>
                </div>
                <div className='flex space-x-10 w-full'>
                  <label className='w-full'>
                    التكلفه للقطعه
                    <input
                      placeholder='التكلفه للقطعه'
                      value={accessoriesFormData.price}
                      name='price'
                      onChange={onAccessoriesChange}
                      className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                      required
                    />
                  </label>
                </div>
                <div className='flex space-x-10 w-full'>
                  <label className='w-full'>
                    تاريخ الدفعه
                    <input
                      placeholder='yyyy-mm-dd'
                      value={accessoriesFormData.processingDate}
                      name='processingDate'
                      onChange={onAccessoriesChange}
                      className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                      required
                      pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}'
                    />
                  </label>
                </div>

                <div className='flex flex-row justify-center space-x-10 w-full mt-5'>
                  <div className='w-1/2'>
                    <Button
                      type='submit'
                      text='تسجيل'
                      isDisabled={
                        !(
                          (accessoriesFormData.fabricTransactionVariantId ||
                            accessoriesFormData.accessoryId) &&
                          accessoriesFormData.quantity &&
                          accessoriesFormData.processingDate &&
                          accessoriesFormData.price
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className='text-center w-full m-5'>متطلبات انتاج</div>
            <table className='table-auto w-full text-center'>
              <thead>
                <tr>
                  <th>الثابت</th>
                  <th>السعر للقطعه</th>
                  {admin.canEdit === 1 && (
                    <th className='text-center w-24'>تعديل</th>
                  )}
                  {admin.canDelete === 1 && (
                    <th className='text-center w-24'>حذف</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredProductionAssets.map((productionAsset) => (
                  <tr
                    className='bg-slate-50'
                    key={`productionAsset-${productionAsset.productionAssetName}`}
                  >
                    <td>{productionAsset.productionAssetName}</td>
                    <td>{productionAsset.price}</td>
                    {admin.canEdit === 1 && (
                      <td className='text-center'>
                        <div
                          className='flex flex-col items-center w-full cursor-pointer'
                          onClick={() => onEditProductionAsset(productionAsset)}
                        >
                          <img src={editIcon} alt='edit' className='h-10' />
                        </div>
                      </td>
                    )}
                    {admin.canDelete === 1 && (
                      <td className='text-center'>
                        <div
                          className='flex flex-col items-center w-full cursor-pointer'
                          onClick={() =>
                            deleteProductInstalment(
                              productionAsset.accessoryTransactionVariantId
                            )
                          }
                        >
                          <img src={deleteIcon} alt='delete' className='h-5' />
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
                <tr className='text-rose-500'>
                  <td>الاجمالى</td>
                  <td>{productionAssetsTotalPrice}</td>
                </tr>
              </tbody>
            </table>
            <form
              onSubmit={async (e) => {
                e.preventDefault();

                e.preventDefault();

                const response = await axios.post(
                  `${settings.api}production-assets/${
                    productionAssetsFormData.productionAssetTransactionVariantId !==
                    ''
                      ? 'update'
                      : 'create'
                  }-variant-transaction`,
                  productionAssetsFormData
                );

                if (response.data.data) {
                  getVariantTransactionDetails();
                  setProductionAssetsFormData(initProductionAssetsFormData);
                }
              }}
            >
              <div className='flex flex-row text-sm text-gray-500 py-4 w-full gap-4'>
                <input
                  type='hidden'
                  name='fabricTransactionVariantId'
                  value={fabricTransactionVariantId}
                />
                <div className='flex flex-row space-x-10 w-full'>
                  <label className='w-full'>
                    متطلبات الانتاج
                    <select
                      value={productionAssetsFormData.productionAssetId}
                      name='productionAssetId'
                      onChange={onProductionAssetsChange}
                      className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                      required
                      pattern='[0-9]{4}-[0-9]2}-[0-9]{2}'
                    >
                      <option value=''>اختر</option>
                      {productionAssets.map((productionAsset) => (
                        <option
                          key={productionAsset.productionAssetId}
                          value={productionAsset.productionAssetId}
                        >
                          {`${productionAsset.productionAssetName}`}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className='flex space-x-10 w-full'>
                  <label className='w-full'>
                    التكلفه
                    <input
                      placeholder='التكلفه'
                      value={productionAssetsFormData.price}
                      name='price'
                      onChange={onProductionAssetsChange}
                      className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                      required
                    />
                  </label>
                </div>
                <div className='flex space-x-10 w-full'>
                  <label className='w-full'>
                    تاريخ الدفعه
                    <input
                      placeholder='yyyy-mm-dd'
                      value={productionAssetsFormData.processingDate}
                      name='processingDate'
                      onChange={onProductionAssetsChange}
                      className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                      required
                      pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}'
                    />
                  </label>
                </div>

                <div className='flex flex-row justify-center space-x-10 w-full mt-5'>
                  <div className='w-1/2'>
                    <Button
                      type='submit'
                      text='تسجيل'
                      isDisabled={
                        !(
                          (productionAssetsFormData.fabricTransactionVariantId ||
                            productionAssetsFormData.productionAssetId) &&
                          productionAssetsFormData.processingDate &&
                          productionAssetsFormData.price
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariantOperationsDetails;
