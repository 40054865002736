import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import settings from '../../config';
import Button from '../Button';
import { useParams } from 'react-router-dom';

const Inventory = () => {
  const [variants, setVariants] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [operations, setOperations] = useState([]);
  const [updated, setUpdated] = useState(false);

  const { inventoryId } = useParams();

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}variants/with-code`);

      if (response.data.statusCode === 200) {
        setVariants(response.data.data);
      }

      const operationResponse = await axios.post(
        `${settings.api}inventory-operation/`,
        {
          inventoryId
        }
      );

      if (operationResponse.data.statusCode === 200) {
        const operations = operationResponse.data.data;
        setOperations(operations);
      }
    })();
  }, [inventoryId]);

  useEffect(() => {
    if (operations.length && !updated) {
      const updatedFabrics = variants.map((variant) => {
        const operation = operations.find(
          (operation) => operation.itemId === variant.fabricTransactionVariantId
        );

        if (operation) {
          return {
            ...variant,
            ...operation
          };
        } else {
          return variant;
        }
      });

      setVariants(updatedFabrics);
      setUpdated(true);
    }
  }, [variants, operations, operations.length, updated]);

  const onChange = (fabricTransactionVariantId, value) => {
    setVariants(
      variants.map((variant) =>
        variant.fabricTransactionVariantId === fabricTransactionVariantId
          ? {
              ...variant,
              actualQuantity: +value
            }
          : variant
      )
    );
  };

  const onSubmit = useCallback(async () => {
    const payload = {
      inventoryId,
      operations: variants.map((variant) => ({
        itemId: variant.fabricTransactionVariantId,
        actualQuantity: variant.actualQuantity,
        quantity: variant.quantity - variant.sold || 0
      }))
    };

    const response = await axios.post(
      `${settings.api}inventory-operation/create`,
      payload
    );

    if (response.data.statusCode === 200) {
      setIsSubmitted(true);
    }
  }, [variants, inventoryId]);

  return (
    <div className='w-full'>
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>اسم الخامه</th>
            {<th>الوزن المتاح</th>}
            {<th>الوزن داخل المخزن</th>}
          </tr>
        </thead>
        <tbody>
          {variants.map((variant) => {
            return (
              <tr
                key={variant.fabricTransactionVariantId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>
                  {variant.variantCode} - {variant.variantName}
                </td>

                <td className={(!isSubmitted && 'blur-md') || ''}>
                  {(
                    (variant.quantity || 0) - (variant.sold || 0)
                  ).toLocaleString('en-US')}
                </td>
                <td>
                  <label className='w-full'>
                    <input
                      onChange={(e) =>
                        onChange(
                          variant.fabricTransactionVariantId,
                          e.target.value
                        )
                      }
                      value={variant.actualQuantity || 0}
                      className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                    />
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='flex justify-center'>
        <Button className='w-1/2 my-5' text='جرد' action={onSubmit} />
      </div>
    </div>
  );
};

export default Inventory;
