import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';
import settings from '../../config';
import { format } from '../../utils';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';
import invoiceIcon from '../../imgs/invoice.png';
import instalment from '../../imgs/instalment.png';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}clients/`);
      if (response.data.statusCode === 200) {
        setClients(response.data.data);
      }
    })();
  }, []);

  const deleteClient = async (clientId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}clients/delete`, {
          clientId
        });

        if (response.data.statusCode === 200) {
          setClients(clients.filter((client) => clientId !== client.clientId));
        }
      }
    });
  };

  let remainingSum = 0;

  return (
    <div className='w-full'>
      <Header text='اضافه عميل' link='/client/form' />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>اسم العميل</th>
            <th>المبلغ الاجمالى</th>
            <th>المبلغ بعد الخصم</th>
            <th>المدفوع</th>
            <th>المتبقى</th>
            <th className='text-center w-24'>الفواتير</th>
            <th className='text-center w-24'>الدفعات</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => {
            const finalPrice =
              client.finalPrice - client.percentage - client.perPiece;

            remainingSum += finalPrice - client.totalPaid;

            return (
              <tr
                key={client.clientId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>
                  <Link
                    to={`/client/details/${client.clientId}`}
                    className='hover:text-sky-500'
                  >
                    {client.clientName}
                  </Link>
                </td>
                <td>{format(client.finalPrice || 0)}</td>
                <td>{format(finalPrice || 0)}</td>
                <td>{format(client.totalPaid || 0)}</td>
                <td>{format(finalPrice - client.totalPaid || 0)}</td>
                <td className='text-center'>
                  <Link to={`/client/invoices/${client.clientId}`}>
                    <img
                      src={invoiceIcon}
                      alt='invoiceIcon'
                      className='my-auto mx-auto h-5'
                    />
                  </Link>
                </td>
                <td className='text-center'>
                  <div className='flex flex-col items-center w-full cursor-pointer'>
                    <div className='font-bold rounded-full w-8'>
                      <Link to={`/variant/instalments/${client.clientId}`}>
                        <img
                          src={instalment}
                          alt='instalment'
                          className='h-6'
                        />
                      </Link>
                    </div>
                  </div>
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link to={`/client/form/${client.clientId}`}>
                      <img src={editIcon} alt='edit' className='inline h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() => deleteClient(client.clientId)}
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            <td colSpan={6} className='text-rose-500'>
              اجمالى المتبقى
            </td>
            <td className='text-rose-500'>{format(remainingSum)}</td>
            <td colSpan={3}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Clients;
