import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Header from '../Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ModalContext } from '../../context';

import settings from '../../config';
import editIcon from '../../imgs/edit.png';
import deleteIcon from '../../imgs/delete.png';
import tShirtIcon from '../../imgs/t-shirt.svg';

const ProductionAssets = () => {
  const [productionAssets, seProductionAssets] = useState([]);
  const [admin] = useLocalStorage('admin');
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}production-assets/`);

      if (response.data.statusCode === 200) {
        seProductionAssets(response.data.data);
      }
    })();
  }, []);

  const deleteBillCategory = async (productionAssetId) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}production-assets/delete`,
          {
            productionAssetId
          }
        );

        if (response.data.statusCode === 200) {
          seProductionAssets(
            productionAssets.filter(
              (asset) => productionAssetId !== asset.productionAssetId
            )
          );
        }
      }
    });
  };

  return (
    <div className='w-full'>
      <Header text='اضافه متطلب انتاج' link='/production-asset/form' />
      <table className='w-full table-auto border-separate border-spacing-1 text-right '>
        <thead>
          <tr>
            <th>مسلسل</th>
            <th>المتطلب انتاج</th>
            <th className='text-center w-24'>تركيب</th>
            {admin.canEdit === 1 && <th className='text-center w-24'>تعديل</th>}
            {admin.canDelete === 1 && <th className='text-center w-24'>حذف</th>}
          </tr>
        </thead>
        <tbody>
          {productionAssets.map((productionAsset) => {
            return (
              <tr
                key={productionAsset.productionAssetId}
                className='bg-white odd:bg-gray-100 hover:bg-orange-100'
              >
                <td>{productionAsset.productionAssetId}</td>
                <td>{productionAsset.productionAssetName}</td>
                <td className='text-center'>
                  <div className='flex flex-col items-center w-full cursor-pointer'>
                    <div className='font-bold rounded-full'>
                      <Link
                        to={`/production-asset/variants/${productionAsset.productionAssetId}`}
                      >
                        <img
                          src={tShirtIcon}
                          alt='tShirtIcon'
                          className='h-8'
                        />
                      </Link>
                    </div>
                  </div>
                </td>
                {admin.canEdit === 1 && (
                  <td className='text-center'>
                    <Link
                      to={`/production-asset/form/${productionAsset.productionAssetId}`}
                      className='flex flex-col items-center w-full cursor-pointer'
                    >
                      <img src={editIcon} alt='edit' className='h-10' />
                    </Link>
                  </td>
                )}
                {admin.canDelete === 1 && (
                  <td className='text-center'>
                    <div
                      className='flex flex-col items-center w-full cursor-pointer'
                      onClick={() =>
                        deleteBillCategory(productionAsset.productionAssetId)
                      }
                    >
                      <img src={deleteIcon} alt='delete' className='h-5' />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProductionAssets;
