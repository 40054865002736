import { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Button from '../Button';

import settings from '../../config';
import logo from '../../imgs/albasha-logo.png';

const MerchantInvoices = () => {
  const { merchantId } = useParams();
  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    transactionDate: ''
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  let totalAmount = 0;
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        (async () => {
          const response = await axios.post(
            `${settings.api}merchants/invoices`,
            {
              merchantId,
              transactionDate: formData.transactionDate
            }
          );

          if (response.data.data) {
            setData(response.data.data);
          }
        })();
      }}
    >
      <div className='w-full lg:w-1/2'>
        <div className='bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4'>
          <div className='flex flex-col text-sm text-gray-500 py-4'>
            <div className='flex flex-row justify-center space-x-10 w-full'>
              <div className='w-full font-bold text-xl text-center mb-5'>
                فاتوره للتاجر {formData.merchantName}
              </div>
            </div>
            <div className='flex space-x-10 w-full justify-center items-center'>
              <label className='w-1/2'>
                <input
                  placeholder='تاريخ الفاتوره'
                  value={formData.transactionDate}
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  name='transactionDate'
                />
              </label>
              <div className='w-1/2 text-center'>
                <div>
                  <Button
                    type='submit'
                    text='بحث'
                    isDisabled={!formData.transactionDate}
                    className='mx-auto text-center w-1/2'
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-row justify-center space-x-10 w-full mt-5'>
              {data && (
                <div className='flex w-full flex-col'>
                  <div className='flex w-full items-center'>
                    <div className='w-1/3 text-center'>
                      مصنع الباشا <br /> للملابس الجاهزه
                    </div>
                    <div className='w-1/3 text-center'>قاتورة</div>
                    <div className='w-1/3 text-center flex flex-col justify-center items-center'>
                      <img src={logo} alt='logo' className='w-24' />
                    </div>
                  </div>
                  <div className='flex w-full mt-5'>
                    <span className=' text-rose-600 ml-5'>تحريرا فى </span>{' '}
                    {formData.transactionDate}
                  </div>
                  <div className='flex w-full mb-5'>
                    <span className=' text-rose-600 ml-5'>
                      المطلوب من السيد
                    </span>{' '}
                    {data[0].merchantName}
                  </div>
                  <hr className='my-5' />
                  <div className='flex w-full'>
                    <table
                      className='table w-full'
                      cellSpacing={10}
                      cellPadding={10}
                    >
                      <thead>
                        <tr className='border-b'>
                          <th>السعر</th>
                          <th>الوزن</th>
                          <th>تاريخ المعامله</th>
                          <th>اسم الخامه</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row) => {
                          totalAmount += row.price;
                          return (
                            <tr
                              key={row.fabricTransactionId}
                              className='text-center border-b'
                            >
                              <td>{row.price}</td>
                              <td>{row.weight}</td>
                              <td>{row.transactionDate}</td>
                              <td>{row.fabricName}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td className='text-center'>{totalAmount}</td>
                          <td colSpan={3}>الاجمالى</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MerchantInvoices;
